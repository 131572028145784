import './index.scss'
import React, { useState, useEffect } from "react";

function MintsContent(props) {
  const christmasList = [
    { img: require('../../../assets/images/home/snowman1.png'), cardImg: require('../../../assets/images/home/starBox.png'), priceInGame: '0.1', logo: require('../../../assets/images/home/element_logo.png'), recyclingPrice: 'Floor Price', name: 'Uncommon' },
    { img: require('../../../assets/images/home/hippoBox.png'), cardImg: require('../../../assets/images/home/mhBox.png'), priceInGame: '20', logo: require('../../../assets/images/home/gameta_logo@2x.png'), recyclingPrice: 'Gaming Value', name: 'mystery' },
  ]
  const [cardActive, setCardActive] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCardActive(cardActive == 0 ? 1 : 0); 
    }, 150);
    return () => {
      clearTimeout(timer)
    }
  }, [cardActive])

  return (
    <div className='mintsContent'>
      {
        props.type === 1 ?
          <div className='inner'>
            {
              christmasList.map((item, index) =>
                <li className='card' key={index}>
                  {
                    index === 1 ?
                      <div className="cardImgs">
                        <img src={item.cardImg} alt="" />
                      </div> :
                      <img className="cardImg" src={item.cardImg} alt="" />
                  }
                  <img src={item.img} className="hippoCard" alt="" />
                  <div className="number">
                    {item.priceInGame}
                    {
                      index === 1 ?
                        <span className="sol">USDT</span> :
                        <span className="sol">BNB</span>
                    }
                  </div>
                  <div className="value">
                    {
                      index === 1 ?
                        <img src={item.logo} className="meFive" alt="" /> :
                        <img src={item.logo} className="me" alt="" />
                    }
                    {item.recyclingPrice}
                  </div>
                  <div className="bottom1">{item.name}</div>
                </li>
              )}
          </div> :
          <div className='other'>
            {
              christmasList.map((item, index) =>
                // className={cardActive === index ? 'cardActive' : 'card'}
                <li className='cardActive' style={{
                  opacity: cardActive == index ? '0.3' : '1'
                }} key={index}>
                  {
                    index === 1 ?
                      <div className="cardImgs">
                        <img src={item.cardImg} alt="" />
                      </div> :
                      <img className="cardImg" src={item.cardImg} alt="" />
                  }
                  <img src={item.img} className="hippoCard" alt="" />
                  <div className="number">
                    {item.priceInGame}
                    {
                      index === 1 ?
                        <span className="sol">USDT</span> :
                        <span className="sol">BNB</span>
                    }
                  </div>
                  <div className="value">
                    {
                      index === 1 ?
                        <img src={item.logo} className="meFive" alt="" /> :
                        <img src={item.logo} className="me" alt="" />
                    }
                    {item.recyclingPrice}
                  </div>
                  <div className="bottom1">{item.name}</div>
                </li>
              )}
          </div>
      }
    </div>
  )
}

export default MintsContent