import { Modal, Tooltip } from 'antd';
import dialogLogo from '../../../assets/images/home/qb@2x.png'
import { ExclamationCircleOutlined  } from '@ant-design/icons';
import "./index.scss";
import { useAccount } from "@particle-network/connect-react-ui";

function AddFunddsDialog(props) {
  const account = useAccount();
  const title = <span>An exchange allows individuals to trade<br/>cryptocurrencies. Compatible crypto<br/>exchanges include Coinbaseᵣ Gemini,<br/>Kraken, eToro, and many other exchanges.</span>
  const titleStyle = {
    width: '260px',
    height: '90px',
    fontSize: '12px',
    textAlign: 'center'
  }
  function handleCancel() {
    props.closeAddDialog()
  }
  // 点击复制关闭当前弹窗
  function copy() {
    let url = document.getElementsByClassName('invitationCode1')[0].innerHTML;//拿到想要复制的值
    console.log(url);
    let copyInput = document.createElement('input');//创建input元素
    document.body.appendChild(copyInput);//向页面底部追加输入框
    copyInput.setAttribute('value', url);//添加属性，将url赋值给input元素的value属性
    copyInput.select();//选择input元素
    document.execCommand("Copy");//执行复制命令
    copyInput.remove();//删除动态创建的节点
    props.closeAddDialog()
  }
  return (
    <Modal wrapClassName='addFunddsDialog' open={props.showAddDialog} keyboard={false} maskClosable={false} onCancel={handleCancel}>
      <div className="dialogContent">
        <div className="dialogHeader">add funds</div>
        <img src={dialogLogo} className="dialogLogoStyle" />
        <div className="dialogRemark">Transfer funds from an
          <Tooltip placement="bottom" title={title} overlayInnerStyle={titleStyle}>
            <a href=""> exchange <ExclamationCircleOutlined /></a>
          </Tooltip>or another wallet to
          <div className="dialogRemark2">your wallet address below:</div>
        </div>
        <div className="dialogBottom">
          <div className="invitationCode">{account}</div>
          <div className="copy" onClick={copy}>Copy</div>
        </div>
      </div>
    </Modal>
  )
}

export default AddFunddsDialog