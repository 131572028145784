import './index.scss'
import {Modal} from 'antd'
import christmasBox from '@/assets/images/home/hippo@2x.png'
import { RightOutlined } from '@ant-design/icons'
// christmasTop

function AwardDialog(props) {
  function handleCancel(type) {
    props.closeAwardDialog(type)
  }
  return (
    <Modal className='award' footer={null} title={null} open={props.showAwardDialog} zIndex={1} keyboard={false} wrapClassName='awardDialog' maskClosable={false} onCancel={handleCancel}>
      <div className='awardContent'>
        <div className='top'></div>
        <div className='box'>
          <img src={props.data?.image} alt="" />
        </div>
        <div className='view'>Prizes have been placed<span onClick={() => handleCancel(1)}>View Now <RightOutlined/></span></div>
        <div className='btn' onClick={handleCancel}>Certain</div>
      </div>
    </Modal>
  )
}

export default AwardDialog