import './index.scss'
import img1 from '@/assets/images/partner/Binance_log@2x.png'
import img2 from '@/assets/images/partner/bnb-chain@2X.png'
import img3 from '@/assets/images/partner/huobi@2x.png'
import img4 from '@/assets/images/partner/logo-arcane@2x.png'
import img5 from '@assets/images/partner/Translate1@4x.png'
import img6 from '@/assets/images/partner/gate@4x.png'
import img7 from '@/assets/images/partner/element.png'
import img8 from '@/assets/images/partner/Rana@4x.png'
import img9 from '@/assets/images/partner/DefinanceX1@4x.png'
import img10 from '@/assets/images/partner/Redline1@4x.png'
import img11 from '@/assets/images/partner/Infinity_Labs@2x.png'
import img12 from '@/assets/images/partner/solanna@2x.png'
import img13 from '@/assets/images/partner/OpenSea-Logo@2x.png'
import img14 from '@/assets/images/partner/MElogo@2x.png'
import img15 from '@/assets/images/partner/dpp.png'
import img16 from '@/assets/images/partner/dappradar@2x.png'
import img17 from '@/assets/images/partner/PancakeSwap-Logo@2x.png'
import img18 from '@assets/images/partner/CoinMarketCapLOGO@4X.png'
import img19 from '@/assets/images/partner/Port1@4x.png'
import img20 from '@/assets/images/partner/quest@4x.png'
import img21 from '@/assets/images/partner/nodereal@4x.png'
import img22 from '@/assets/images/partner/name@4x.png'
import img23 from '@/assets/images/partner/chainlink@2x.png'
import img24 from '@assets/images/partner/damn@4x.png'
import imgs1 from '@/assets/images/partner/certik@2x.png'
import imgs2 from '@/assets/images/partner/logo-blue@4x.png'
import imgs3 from '@/assets/images/partner/logo.png'
import imgs4 from '@/assets/images/partner/DAO_logo@4x.png'
import imgs5 from '@/assets/images/partner/p2m@2x.png'
import imgs6 from '@/assets/images/partner/bsc@2x.png'
import leftImg from '@/assets/images/home/left.png'
import rightImg from '@/assets/images/home/right.png'
import ttImg from '@/assets/images/home/icon_tt@2x.png'
import disccord from '@/assets/images/home/icon_disccord@2x.png'
import {config} from '@/config'

function Sponsor() {
  function goTwitter() {
    window.open(config.twitterUrl)
  }
  function goDiscord() {
    window.open(config.discordUrl)
  }
  return(
    <div className='sponsor'>
      <div className="roadAsid">
        <div className="roadList">
          <div className="roadmap">
            <img className="partnerLeft" src={leftImg} alt="" />
            <div className="road">Ecosystem Partners</div>
            <img className="partnerRight" src={rightImg} alt="" />
          </div>
        </div>
      </div>
      <div className='sponsorList'>
        <div className="sponsorOne">
          <div className="sponsorImg"><img className="imgOne" src={img1} alt="" /></div>
          <div className="sponsorImg"><img className="imgTwo" src={img2} alt="" /></div>
          <div className="sponsorImg"><img className="imgThree" src={img3} alt="" /></div>
          <div className="sponsorImg"><img className="imgFour" src={img4} alt="" /></div>
          <div className="sponsorImg"><img className="imgFive" src={img5} alt="" /></div>
          <div className="sponsor1"><img className="imgSix" src={img6} alt="" /></div>
        </div>
        <div className="sponsorOne">
          <div className="sponsorImg"><img className="imgSeven" src={img7} alt="" /></div>
          <div className="sponsorImg"><img className="imgEight" src={img8} alt="" /></div>
          <div className="sponsorImg"><img className="imgNine" src={img9} alt="" /></div>
          <div className="sponsorImg"><img className="imgTen" src={img10} alt="" /></div>
          <div className="sponsorImg"><img className="imgEle" src={img11} alt="" /></div>
          <div className="sponsor1"><img className="imgTwe" src={img12} alt="" /></div>
        </div>
        <div className="sponsorOne">
          <div className="sponsorImg"><img className="imgThi" src={img13} alt="" /></div>
          <div className="sponsorImg"><img className="imgFourteen" src={img14} alt="" /></div>
          <div className="sponsorImg"><img className="imgFiveteen" src={img15} alt="" /></div>
          <div className="sponsorImg"><img className="imgSixteen" src={img16} alt="" /></div>
          <div className="sponsorImg"><img className="imgSeventeen" src={img17} alt="" /></div>
          <div className="sponsor1"><img className="imgEighteen" src={img18} alt="" /></div>
        </div>
        <div className="sponsorOne">
          <div className="sponsorImg"><img className="imgNines" src={img19} alt="" /></div>
          <div className="sponsorImg"><img className="imgTwenty" src={img20} alt="" /></div>
          <div className="sponsorImg"><img className="imgTwenOne" src={img21} alt="" /></div>
          <div className="sponsorImg"><img className="imgTwenTwo" src={img22} alt="" /></div>
          <div className="sponsorImg"><img className="imgTwenThree" src={img23} alt="" /></div>
          <div className="sponsor1"><img className="imgTwenFour" src={img24} alt="" /></div>
        </div>
        <div className="sponsorTwo">
          <div className="sponsorImg"><img className="imgOne" src={imgs1} alt="" /></div>
          <div className="sponsorImg"><img className="imgTwo" src={imgs2} alt="" /></div>
          <div className="sponsorImg"><img className="imgThree" src={imgs3} alt="" /></div>
          <div className="sponsorImg"><img className="imgFour" src={imgs4} alt="" /></div>
          <div className="sponsorImg"><img className="imgFive" src={imgs5} alt="" /></div>
          <div className="sponsor1"><img className="imgSix" src={imgs6} alt="" /></div>
        </div>
      </div>
      <div className="footer">
        <div className="footerSoga">
          <div>© 2022 SOGA. </div>
        </div>
        <div className="footerIcon">
          <img src={ttImg} onClick={goTwitter} />
          <img src={disccord} onClick={goDiscord} />
        </div>
      </div>
    </div>
  )
}

export default Sponsor