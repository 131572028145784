import "./index.scss";
import { useEffect, useState } from "react";
import imgLogo1 from '../../../assets/images/home/logo.png';
import imgLogo2 from '../../../assets/images/home/logo1.png';
import shoucang from '../../../assets/images/home/shoucangjia@2x.png';
import AddFunddsDialog from "../addFunddsDialog/index";
import useWeb3 from "@hooks/useWeb3";
import { ConnectButton } from '@particle-network/connect-react-ui';
import RulesDialog from '../rulesDialog/index'
import RuleDescription from '../ruleDescription/index'
import CollectionDialog from '../collectionDialog/index'
import { config } from '@/config';
import { useLocation, useNavigate } from 'react-router-dom'
import vipImg from '@/assets/images/home/vipImg.png'
// import FreeWhiteDialog from '../freeWhiteDialog'
import WhiteListDialog from '../whiteListDialog'
import { useAccount, useConnectModal } from "@particle-network/connect-react-ui";
import { useDispatch, useSelector } from 'react-redux';

function Header(props) {
  useWeb3()
  // const [visible, setVisible] = useState(true);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showRulesDialog, setShowRulesDialog] = useState(false);
  const [showRuleDescription, setShowRuleDescription] = useState(false)
  const [showCollectionDialog, setShowCollectionDialog] = useState(false);
  const [showWhiteListDialog, setShowWhiteListDialog] = useState(false)
  const [activeStat, setActiveStat] = useState(sessionStorage.getItem('activeStat'));
  const [whiteCardList, setWhiteCardList] = useState([])
  const history = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const account = useAccount();
  const connectKit = useConnectModal();
  const { whiteList } = useSelector(state => {
    return {
      whiteList: state.globalModule.whiteList
    }
  })

  const listArr = [
    <li key={1} className={activeStat === '0' && (history.pathname === '/') ? "activeCss" : "liItem"} onClick={changActive} >MINT</li>,
    <li key={2} className={activeStat === '1' && (history.pathname === '/' || history.pathname === '/trade') ? "activeCss" : "liItem"} onClick={goMe}>Trade</li>,
    <li key={3} onClick={goGameta}>Gameta</li>,
    history.pathname !== '/' && history.pathname !== '/trade' ?
    <li key={4}>
      <div onClick={openRulesDialog}>
        Rules
      </div>
    </li>: undefined,
    <li key={5} onClick={gitbook}>Q&A</li>,
    <li key={6} className="lucky" >
      <div className="block" onClick={openCollectionDialog}>
        <img className="shou" src={shoucang} />My Collection
      </div>
    </li>
  ]

  function changActive() {
    navigate('/')
    setActiveStat('0')
    sessionStorage.setItem('activeStat', 0)
    props.tradeIndex(0)
  }
  // 设置打开充值弹框
  function setAddDialog() {
    setShowAddDialog(true)
  }
  // 设置关闭充值弹框
  function closeAddDialog() {
    setShowAddDialog(false)
  }
  function goMe() {
    navigate('/trade')
    setActiveStat('1')
    sessionStorage.setItem('activeStat', 1)
    props.tradeIndex(1)
  }
  function goGameta() {
    window.open(config.gametaHomeUrl)
  }
  // 打开规则弹窗
  function openRulesDialog() {
    const issuanceType = sessionStorage.getItem('issuanceType')
    if (issuanceType === '1of2') {
      setShowRuleDescription(true)
    } else {
      setShowRulesDialog(true)
    }
  }
  // 关闭规则弹窗
  function closeRulesDialog() {
    setShowRulesDialog(false)
  }
  function closeRuleDescription() {
    setShowRuleDescription(false)
  }

  // 打开收集弹窗
  function openCollectionDialog() {
    setShowCollectionDialog(true)
  }
  // 关闭收集弹窗
  function closeCollectionDialog() {
    setShowCollectionDialog(false)
  }
  function gitbook() {
    window.open(config.gitbookUrl)
  }
  // const dropdownStyle = {
  //   border: '1px solid #FECE1E',
  //   background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.3) 15.93%, rgba(255, 255, 255, 0.3) 100%)',
  //   boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3), inset 0px -4px 60px rgba(166, 139, 197, 0.4), inset 0px 4px 20px rgba(166, 139, 197, 0.4)',
  //   backdropFilter: 'blur(45px)',
  //   padding: '0 20px',
  //   borderRadius: '20px',
  //   hover: 'none'
  // }

  function openWhiteList() {
    if (account) {
      setShowWhiteListDialog(true)
    } else {
      connectKit.openConnectModal()
    }
  }
  
  function closeWhiteListDialog() {
    setShowWhiteListDialog(false)
  }

  function goHome () {
    navigate('/')
    setActiveStat('0')
  }

  return (
    <div className="navbar">
      <div className="right-menu">
        <div className="logo" onClick={goHome}>
          <img src={imgLogo1} className={"logo1"} alt="" />
          <div className="logox">x</div>
          <img src={imgLogo2} className={"logo2"} alt="" />
        </div>
        <div className="tabs">
          <div className="listCss">
            {listArr}
          </div>
          { 
            activeStat === '0' && (history.pathname === '/' || history.pathname === '/trade')  && <span className="lineCss" /> 
          }
          {
            activeStat === '1' && (history.pathname === '/' || history.pathname === '/trade' )&& <span className="tradLine" />
          }
        </div>
        <div className="avatar-wrapper">
          <ConnectButton></ConnectButton>
          {
            history.pathname !== '/' && whiteList.length > 0 && account &&
            <img src={vipImg} className='vipImg' onClick={openWhiteList} alt="" />
          }
        </div>
        {/* <Dropdown arrow="true" trigger="click" placement="bottom" overlay={
          <Menu style={dropdownStyle}>
            {
              !hasConnect ?
              <Popular />:
              <Fundds setAddDialog={setAddDialog} />
            }
          </Menu>
        }>
          <div className="avatar-wrapper">
            <div className="wallet"> 
              <div className="step1"><img src={imgWallet}  />wallet</div> 
            </div>
          </div>
        </Dropdown> */}
        <AddFunddsDialog showAddDialog={showAddDialog} closeAddDialog={closeAddDialog} />
        <RulesDialog showRulesDialog={showRulesDialog} closeRulesDialog={closeRulesDialog} />
        <RuleDescription showRuleDescription={showRuleDescription} closeRuleDescription={closeRuleDescription} />
        <CollectionDialog showCollectionDialog={showCollectionDialog} closeCollectionDialog={closeCollectionDialog} />
        {/* <FreeWhiteDialog showFreeWhiteDialog={showFreeWhiteDialog} closeFreeWhiteDialog={closeFreeWhiteDialog} /> */}
        <WhiteListDialog showWhiteListDialog={showWhiteListDialog} closeWhiteListDialog={closeWhiteListDialog} whiteCardList={whiteCardList} />
      </div>
    </div>
  );
}

export default Header;