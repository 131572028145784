import './index.scss'
import { Modal } from 'antd'
import fuzhiImg from '../../../assets/images/home/fuzhi@2x.png';
import { useAccount,useParticleProvider } from "@particle-network/connect-react-ui";
import { useEffect, useState } from 'react';
import { isEVMProvider } from "@particle-network/connect";
import {useSelector} from 'react-redux'


function BalanceDialog(props) {
  const account = useAccount();
  const provider = useParticleProvider();
  const [balance, setBalance] = useState(0);
  function handleCancel(type) {
    props.closeBalanceDialog(type)
  }
  function showAddfunDialog() {
    handleCancel(1)
  }
  function copy() {
    let url = document.getElementsByClassName('invitationCode1')[0].innerHTML;//拿到想要复制的值
    let copyInput = document.createElement('input');//创建input元素
    document.body.appendChild(copyInput);//向页面底部追加输入框
    copyInput.setAttribute('value', url);//添加属性，将url赋值给input元素的value属性
    copyInput.select();//选择input元素
    document.execCommand("Copy");//执行复制命令
    copyInput.remove();//删除动态创建的节点
  } 

  const { chainId,actualPrice } = useSelector(state => {
    // console.log(state,"======state")
    return {
      chainId: state.globalModule.chain,
      actualPrice:state.orderModule.orderInfo?.order.actualPrice
    }
  })
  useEffect(() => {
    if (account && provider && (isEVMProvider(provider))) {
      window.web3.eth.getBalance(account).then((value) => {
        setBalance(window.web3.utils.fromWei(value, "ether"))
      })
    }
  }, [account,chainId])
  return (
    <Modal className='balance' footer={null} title={null} open={props.showBalanceDialog} keyboard={false} maskClosable={false} wrapClassName='balanceDialog' onCancel={handleCancel}>
      <div className='dialogContent'>
        <div className="dialogHeader">add funds to purchcase</div>
        {/* {{totalPrice}} */}
        <div className="dialogNeed"><span>You need {actualPrice} BNB + </span><i>gas fees</i><br />Transfer funds to your wallet or add funds with a card.<br />It can take up to a minute for your balance to updadte.</div>
        <div className="dialogRemark">
          <div>Your BNB wallet:</div>
          {/* {{balance}} */}
          <div>Balance:{balance} BNB</div>
        </div>
        <div className="invitationCode">
          {/* {{wallet}} */}
          <div className='invitationCode1'>{account}</div>
          <img onClick={copy} src={fuzhiImg} alt="" />
        </div>
        {/* v-if="stat === '0'" */}
        {/* 当前页面请求余额接口需五秒请求一次 1.如果用户充值之后依然停留在当前页面则按钮亮起可以抽奖 2.如果关闭弹框则清除定时器 */}
        <div className="btns">Continue</div>
        {/* <div v-if="stat === '1'" class="btn" @click="sellDialog">Continue</div> */}
        <a className="addText" onClick={showAddfunDialog}>Add funds</a>
      </div>
    </Modal>
  )
}

export default BalanceDialog