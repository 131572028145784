import './index.scss'
import { Modal } from 'antd';
import bottomImg from '@/assets/images/home/white-bottom.png';

const remarkList = [
  'No charge for MINT',
  'Scope of application: 1 of 5',
  'MINT option: 5 MINT',
  'MINT Total Price: 0 (0.06) BNB/MINT'
]

function FreeWhiteDialog(props) {
  function handleCancel(type) {
    props.closeFreeWhiteDialog(type)
  }
  return(
    <Modal footer={null} title={null} open={props.showFreeWhiteDialog} keyboard={false} maskClosable={false} wrapClassName='freeWhiteDialog' onCancel={()=> {handleCancel(0)}}>
      <div className='dialogContent'>
        <div className='header'>You will use the following privileges in this MINT</div>
        <div className='card'>
          <div className='cardInner'>
            <div className='title'>{props.title}</div>
            <div className='remark'>
              {
                props.remarkList.map((item, index) =>
                  <div key={index} dangerouslySetInnerHTML={{__html: item}} />
                )}
            </div>
          </div>
          <img src={bottomImg} className='bottom' alt="" />
          <div className='btn' onClick={()=> {handleCancel(1)}}>Certain</div>
        </div>
      </div>
    </Modal>
  )
}

export default FreeWhiteDialog