import './index.scss'
import { Modal } from 'antd'
import { useState, useEffect } from "react";
import { getUserOrders_Func, setUserOrders } from '../../../features/globalClice';
import { useDispatch, useSelector } from 'react-redux';
import ResultDialog from '../resultDialog/index'
import CollectionDialog from '../collectionDialog/index'
import CountDown from '@components/countDown';
import { useAccount } from "@particle-network/connect-react-ui";
// import { LoadingOutlined } from '@ant-design/icons'

function LuckListDialog(props) {
  const dispatch = useDispatch();
  const [showResultDialog, setShowResultDialog ] = useState(false);
  const [showCollectionDialog, setShowCollectionDialog] = useState(false);
  const [quantity, setQuantity ] = useState(0)
  const [orderInfo, setOrderInfo] = useState({})
  const account = useAccount();
  function handleCancel(type) {
    props.closeLuckDialog(type)
  }
  const userOrdersList = useSelector(state => { 
    return state.globalModule.userOrdersList
  })
  useEffect(() => {
    if (props.showLuckDialog) {
      dispatch(setUserOrders([]))
      if (account) {
        dispatch(getUserOrders_Func());
      }
    }
  }, [props.showLuckDialog])
  function viewResult(item) {
    setQuantity(item.quantity)
    const obj = {
      order: {
        id: item.id
      }
    }
    setOrderInfo(obj)
    openResultDialog()
  }
  function openResultDialog() {
    setShowResultDialog(true)
  }
  // 关闭结果页面弹窗
  function closeResultDialog(type) {
    if (type === 1) {
      setShowResultDialog(false)
      openCollectionDialog()
    } else if(type === 2){
      setShowResultDialog(false)
      setTimeout(() => {
        handleCancel(1)
      }, 300)
    } else {
      setShowResultDialog(false)
    }
  }
  // 打开收集弹窗
  function openCollectionDialog() {
    setShowCollectionDialog(true)
  }
  // 关闭收集弹框
  function closeCollectionDialog() {
    setShowCollectionDialog(false)
  }
  return (
    <Modal className='luck' footer={null} title={null} open={props.showLuckDialog} keyboard={false} maskClosable={false} wrapClassName='luckListDialog' onCancel={handleCancel}>
      <div className='dialogContent'>
        <div className="luckHeader">MY LUCKY mint</div>
        <div className='tableHeader'>
          <div className='mintItem'>Mint</div>
          <div className='constItem'>Cost</div>
          <div className='statusItem'>Status</div>
          <div className='timeItem'>Time</div>
        </div>
        <div className='tableContent'>
          {/* {
            userOrdersList === null ?
            <>
              <LoadingOutlined className='loadIcon' />
            </>:
            <> */}
              {
                userOrdersList.length === 0?
                <div className='noData'>No data</div>:
                <div>
                  {
                    userOrdersList.map((item, index) =>
                      <div key={index} className="tableItem">
                        <div className='mintCss'>{item.quantity} Mint</div>
                        <div className='costCss'>{item.actualPrice} BNB</div>
                        {
                          item.status === 0 ?
                            <div className='statusCss'>Paying</div> :
                            <div className='statusCss'></div>
                        }
                        {
                          item.status === 1 ?
                            <div className='statusCss'>Payment completed</div> :
                            <div className='statusCss'></div>
                        }
                        {
                          item.status === 2 ?
                            <div className='statusCss'>Minting</div> :
                            <div className='statusCss'></div>
                        }
                        {
                          item.status === 3 ?
                            <div className='statusCss'>Success</div> :
                            <div className='statusCss'></div>
                        }
                        {
                          item.status === 4 ?
                            <div className='statusCss'>Payment Cancelled</div> :
                            <div className='statusCss'></div>
                        }
                        {
                          item.status === 5 ?
                            <div className='statusCss'>Refunding</div> :
                            <div className='statusCss'></div>
                        }
                        {
                          item.status === 6 ?
                            <div className='statusCss'>Refunded</div> :
                            <div className='statusCss'></div>
                        }
                        <div className='timeCss'>{item.time}</div>
                        {
                          item.status !== 0 && item.status !== 4&&
                          <div className='viewCss' onClick={()=>viewResult(item)}>view</div>
                          
                        }
                        {
                          item.status === 0&&
                          <div className='viewCss'><CountDown createTime={item.createTime}/></div>
                        }
                      </div>
                    )
                  }
                </div>
              }
            {/* </>
          } */}
        </div>
      </div>
      {/* orderInfo={orderInfo} */}
      <ResultDialog showResultDialog={showResultDialog} closeResultDialog={closeResultDialog} quantity={quantity} orderInfo={orderInfo} />
      <CollectionDialog showCollectionDialog={showCollectionDialog} closeCollectionDialog={closeCollectionDialog} />
    </Modal>
  )
}

export default LuckListDialog