import { RootState } from '@/store';
import { refreshToken_Func } from '@features/loginClice'
import { useAccount } from '@particle-network/connect-react-ui';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { config } from '@/config'
export default function Index() {
    const dispatch = useDispatch();
    const account = useAccount();
    const token = useSelector((state: RootState) => {
        return state.loginModule.token;
    })
    useEffect(() => {
        let timer: any = null;
        console.log(account, token)
        if (account && token && timer === null) {
            timer = setTimeout(() => {
                dispatch(refreshToken_Func())
            }, config.refreshTokenTime)
        } else if (timer != null) {
            clearTimeout(timer);
            timer = null;
        }

        return () => {
            if (timer != null) {
                clearTimeout(timer);
                timer = null;
            }
        }
    }, [account, token])

}