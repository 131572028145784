import './index.scss'
import { Modal } from 'antd';

function RulesDialog(props) {
  function handleCancel() {
    props.closeRulesDialog()
  }
  return(
      <Modal className='rules' footer={null} title={null} open={props.showRulesDialog} keyboard={false} maskClosable={false} wrapClassName='rulesDialog' getContainer="false" onCancel={handleCancel}>
        <div className="rulesContent">
          <div className="rulesHeader">Rule Description</div>
          <div className="rulesContai">
            <div className="one">
              <div>Name</div>
              <div>TYPE</div>
              <div>Gameta value</div>
              <div>NFT Market Price</div>
              <div>Mint Probability</div>
              <div>Buyback Guaranteed by</div>
            </div>
            <div className="two">
              <div>Lucky Hippo<br/>Gameta Hippo Club</div>
              <div>PFP NFT<br/>In-game Items</div>
              <div>10 USDT</div>
              <div>Floor Price*<br/>0.04 BNB</div>
              <div>45.755%</div>
              <div>Community Network</div>
            </div>
            <div className="two">
              <div>Uncommon Hippo<br/>Gameta Hippo Club</div>
              <div>PFP NFT<br/>In-game Items</div>
              <div>28 USDT</div>
              <div>Floor Price*<br/>0.1 BNB</div>
              <div>3.75%</div>
              <div>Community Network</div>
            </div>
            <div className="two">
              <div>Rare Hippo<br/>Gameta Hippo Club</div>
              <div>PFP NFT<br/>In-game Items</div>
              <div>112 USDT</div>
              <div>Floor Price*<br/>0.4 BNB</div>
              <div>0.45%</div>
              <div>Community Network</div>
            </div>
            <div className="two">
              <div>Epic Hippo<br/>Gameta Hippo Club</div>
              <div>PFP NFT<br/>In-game Items</div>
              <div>420 USDT</div>
              <div>Floor Price*<br/>1.5 BNB</div>
              <div>0.045%</div>
              <div>Community Network</div>
            </div>
            <div className="two">
              <div>GMB1<br/>Game Mystical Box</div>
              <div>In-game Items</div>
              <div>10 USDT</div>
              <div>Play To Earn</div>
              <div>50%</div>
              <div>/</div>
            </div>
            <div className="last">
              <div>HHJC<br/>Hpyer Hippo Jokey Club</div>
              <div>PFP NFT</div>
              <div>∞</div>
              <div>∞</div>
              <div>Obtained from official campaigns only</div>
              <div>/</div>
            </div>
          </div>
          <div className="most">*Most recent 7-day average price</div>
        </div>
      </Modal>
  )
}

export default RulesDialog