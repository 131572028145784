import "./index.scss";
import React, { useState, useEffect } from "react";
import Header from '../../components/pc/header/index'
import Support from '../../components/pc/support/index'
// import LuckMintList from '../../components/pc/luckMintList/index' 
import { isRestrictedCountry_Func, issuance_Func, getUrls_Func, getHyperMint_Func, getPrice_Func } from '../../features/globalClice'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '@/store';
import kefuImg from '../../assets/images/home/kefu.png'
import How_to_sellImg from '@assets/images/home/How_to_sell.svg';
import VectorImg from '@assets/images/home/Vector.svg'; 
import { config } from "@/config";
import { Outlet, Navigate } from "react-router-dom";
import { setTradIndexStat } from '@features/globalClice';
import Load from '../pcView/load'

function Index() {    
  // 调用接口设置当前是否为禁用地区展示对应页面
  const [country, setCountry] = useState(false)
  const [pageStat, setPageStat] = useState(false)
  const dispatch = useDispatch();

  const { loadStat }: { loadStat:boolean } = useSelector((state: RootState) => {
    return {
      loadStat: state.globalModule.loadStat
    }
  })
 
  useEffect(() => {
    dispatch(isRestrictedCountry_Func((bo: boolean) => {
      if (bo) {
        setCountry(false)
        setPageStat(true)
      } else {
        setCountry(true)
        dispatch(issuance_Func())
        dispatch(getUrls_Func())
        dispatch(getPrice_Func())
      }
    }))
    dispatch(getHyperMint_Func());
  }, [])

  function tradeIndex(type: Number) {
    dispatch(setTradIndexStat(type !== 0));
  }
  function goDiscord() {
    window.open(config.discordUrl)
  }
  // const isMobile: any = localStorage.getItem("isMobile")
 
  // if (isMobile == 1) {
  //   return <Navigate to="/h5" replace={true} />
  // }
  return (
    <div>
      {
          country ?
          <div className="hippo">
            <div className="isFalse">
              <div className="hoverDIV">
                <div className="contair">
                  <a className="kefu" target="_blank" href={config.youtuBeUrl} >
                    <img src={How_to_sellImg} alt="" />
                  </a>
                  <div className="kefu" >
                    <img src={kefuImg} onClick={goDiscord} alt="" />
                  </div>
                  <a className="kefu" target="_blank" href={config.gametaUrl}>
                    <img src={VectorImg} alt="" />
                  </a>
                </div>
              </div>
              <Header tradeIndex={tradeIndex} />
              <Outlet />
            </div>
          </div>:
          <div className="hippoElse">
            {
              pageStat &&
              <div className="isTrue">
                <Support />
              </div>
            }
          </div>
      }
      {
        loadStat &&
        <Load />
      }
    </div>
  )
}

export default Index;

