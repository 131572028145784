import { axios as ax } from '@utils/request' 
import { config } from '../config';
import axios from 'axios'
import { v4 } from 'uuid'


export function seaport_listing(chainId: number, params: any) {
    return ax({
        url: `https://api-market.particle.network/chains/${chainId}/listing`,
        method: 'post',
        data: params
    })
}


export function getTokens(chainId: number, params: string[]) {
    return axios.post('https://rpc.particle.network/evm-chain', {
        chainId: chainId,
        jsonrpc: '2.0',
        id: v4(),
        method: 'particle_getNFTs',
        params: params,
    }, {
        auth: {
            username: config.projectId,
            password: config.clientKey,
        }
    });
}
