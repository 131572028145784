import './index.scss'
import SwiperCore, { Autoplay } from 'swiper/core' // 引入核心插件和自动播放组件
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useState, useEffect } from 'react'
import { RedoOutlined } from '@ant-design/icons'
import { Slider } from 'antd-mobile'
import { issuance_Func } from '@features/globalClice'
import { useDispatch, useSelector } from 'react-redux';
import { setQuantity, getHyperMint_Func, getInfo_func } from '../../features/globalClice';
import floorLogo from '../../assets/images/home/element_logo.png';
import gamingLogo from '../../assets/images/home/gameta_logo@2x.png';
import { getImage,getBgImage } from '@utils/stringFormat'

SwiperCore.use([Autoplay])

const dataList = [

  { img: require('../../assets/images/home/GHC3.gif'), cardImg: require('../../assets/images/h5/uncommon5.png'), priceInGame: '0.4', logo: require('../../assets/images/home/element_logo.png'), recyclingPrice: 'Floor Price' },
  { img: require('../../assets/images/home/GHC4.gif'), cardImg: require('../../assets/images/h5/rare5.png'), priceInGame: '1.5', logo: require('../../assets/images/home/element_logo.png'), recyclingPrice: 'Floor Price' },
  { img: require('../../assets/images/home/HHJC.gif'), cardImg: require('../../assets/images/h5/hyper5.png'), priceInGame: '5', logo: require('../../assets/images/home/element_logo.png'), recyclingPrice: 'Floor Price', unit: "BNB" },

  { img: require('../../assets/images/home/GHC1.gif'), cardImg: require('../../assets/images/h5/epic5.png'), priceInGame: '0.04', logo: require('../../assets/images/home/element_logo.png'), recyclingPrice: 'Floor Price' },
  { img: require('../../assets/images/home/GHC2.gif'), cardImg: require('../../assets/images/h5/lucky5.png'), priceInGame: '0.1', logo: require('../../assets/images/home/element_logo.png'), recyclingPrice: 'Floor Price' },
  { img: require('../../assets/images/home/GMB1.jpg'), cardImg: require('../../assets/images/h5/mystery5.png'), priceInGame: '10', logo: require('../../assets/images/home/gameta_logo@2x.png'), recyclingPrice: 'Gaming Value' },
] 
const marks = {
  2: '1',
  33: '5',
  66: '10',
  99: '30'
}

export default function Content() {
  const dispatch = useDispatch()

  const [topMint, setTopMint] = useState([])
  const [cardActive, setCardActive] = useState(0)
  const [num, setNum] = useState(180)

  const [cardList, setCardList] = useState(dataList)

  const { issuanceObj, hyperMint, urls } = useSelector((state) => {
    return {
      issuanceObj: state.globalModule.issuance,
      hyperMint: state.globalModule.hyperMintList,
      urls: state.globalModule.urls
    }
  })
  useEffect(() => {
    const timer = setTimeout(() => {
      if (cardActive < 5) {
        setCardActive(cardActive + 1)
      } else if (cardActive > 4) {
        setCardActive(0)
      }
    }, 300);
    return () => {
      clearTimeout(timer)
    }
  }, [cardActive])
  useEffect(() => {
    dispatch(setQuantity(5))
  }, [])
  useEffect(() => {
    setHyperMint()
  }, [hyperMint])

  useEffect(() => {
    if (issuanceObj?.id) {
      dispatch(getInfo_func(issuanceObj.id, (list) => {
        let array = [...cardList]
        if (list.length === 5) {
          array[5] = {
            ...array[5],
            img: getImage(urls, list[4].image),
            priceInGame: Number(list[4].recyclingPrice) > 0 ? list[4].recyclingPrice : list[4].priceInGame,
            recyclingPrice: Number(list[4].recyclingPrice) > 0 ? 'Floor Price' : 'Gaming Value',
            unit: Number(list[4].recyclingPrice) > 0 ? 'BNB' : 'USDT',
            cardImg:getBgImage('h5', list[4].level,5),
            logo: Number(list[4].recyclingPrice) > 0 ? floorLogo : gamingLogo
          }

          array[0] = {
            ...array[0],
            img: getImage(urls, list[3].image),
            priceInGame: Number(list[3].recyclingPrice) > 0 ? list[3].recyclingPrice : list[3].priceInGame,
            recyclingPrice: Number(list[3].recyclingPrice) > 0 ? 'Floor Price' : 'Gaming Value',
            unit: Number(list[3].recyclingPrice) > 0 ? 'BNB' : 'USDT',
            cardImg:getBgImage('h5', list[3].level,5),
            logo: Number(list[3].recyclingPrice) > 0 ? floorLogo : gamingLogo
          }
          array[4] = {
            ...array[4],
            img: getImage(urls, list[2].image),
            priceInGame: Number(list[2].recyclingPrice) > 0 ? list[2].recyclingPrice : list[2].priceInGame,
            recyclingPrice: Number(list[2].recyclingPrice) > 0 ? 'Floor Price' : 'Gaming Value',
            unit: Number(list[2].recyclingPrice) > 0 ? 'BNB' : 'USDT',
            cardImg:getBgImage('h5', list[2].level,5),
            logo: Number(list[2].recyclingPrice) > 0 ? floorLogo : gamingLogo
          }

          array[1] = {
            ...array[1],
            img: getImage(urls, list[1].image),
            priceInGame: Number(list[1].recyclingPrice) > 0 ? list[1].recyclingPrice : list[1].priceInGame,
            recyclingPrice: Number(list[1].recyclingPrice) > 0 ? 'Floor Price' : 'Gaming Value',
            unit: Number(list[1].recyclingPrice) > 0 ? 'BNB' : 'USDT',
            cardImg:getBgImage('h5', list[1].level,5),
            logo: Number(list[1].recyclingPrice) > 0 ? floorLogo : gamingLogo
          }
          array[3] = {
            ...array[3],
            img: getImage(urls, list[0].image),
            priceInGame: Number(list[0].recyclingPrice) > 0 ? list[0].recyclingPrice : list[0].priceInGame,
            recyclingPrice: Number(list[0].recyclingPrice) > 0 ? 'Floor Price' : 'Gaming Value',
            unit: Number(list[0].recyclingPrice) > 0 ? 'BNB' : 'USDT',
            cardImg:getBgImage('h5', list[0].level,5),
            logo: Number(list[0].recyclingPrice) > 0 ? floorLogo : gamingLogo
          }

          setCardList(array)
        }
      }))
    }
  }, [issuanceObj?.id, urls])








  function setHyperMint() {
    const arr = []
    const hyperMintList = [];
    const mintList = hyperMint
    mintList.forEach((item, index) => {
      if (index < 10) {
        hyperMintList.push(item)
      }
    })
    for (let k = 0; k < hyperMintList.length; k++) {
      const obj = {}
      obj.priceInGame = hyperMintList[k].priceInGame
      obj.recyclingPrice = hyperMintList[k].recyclingPrice
      let image = hyperMintList[k].images
      const newImage = image.split(",")
      obj.images1 = newImage[0] ? newImage[0] : ''
      obj.images2 = newImage[1] ? newImage[1] : ''
      obj.newBaby = hyperMintList[k].wallet.substring(0, 2) + '...' + hyperMintList[k].wallet.substring(hyperMintList[k].wallet.length - 2, hyperMintList[k].wallet.length)
      arr.push(obj)
    }
    setTopMint(arr)
  }
  function refreshData() {
    setNum(num + 180)
    const c = document.getElementsByClassName('refresh')
    c[0].style.transform = 'rotateZ(' + num + 'deg)'
    c[0].style.transition = 'all 0.6s'
    getIssuance()
  }

  function getIssuance() {
    dispatch(issuance_Func())
  }

  // 切换抽奖次数
  function getQuantity(value) {
    if (value === 2) {
      dispatch(setQuantity(1))
    } else if (value === 33) {
      dispatch(setQuantity(5))
    } else if (value === 66) {
      dispatch(setQuantity(10))
    } else if (value === 99) {
      dispatch(setQuantity(30))
    }
  }
  return (
    <div className='cardContents'>
      <div className='topRoll'>
        {
          topMint.length !== 0 &&
          <Swiper
            className="h5Swiper-container swiper-no-swiping"
            spaceBetween={0}
            slidesPerView={1}
            autoplay={{
              delay: 1000, // 默认延迟3s播放下一张
              stopOnLastSlide: false, // 播放到最后一张时停止：否
              disableOnInteraction: false // 用户操作轮播图后停止 ：是
            }}
            direction='vertical'
            loop
          >
            {
              topMint.map((item, index) =>
                <SwiperSlide className="h5Swiper-slide" key={index}>
                  <div className="baby">
                    <div className="beele">{item.newBaby}</div>
                  </div>
                  <span className="successful">Won<span className="sol">{' ' + item.recyclingPrice + ' '}</span> BNB of Lucky Mint</span>
                  <div className="prize">
                    {
                      item.images1 && <img src={item.images1} alt="" />
                    }
                    {
                      item.images2 && <img src={item.images2} alt="" />
                    }
                    {
                      item.images2 && <div className="ellipsis">...</div>
                    }
                  </div>
                </SwiperSlide>
              )
            }
          </Swiper>
        }
      </div>
      <div className='card'>
        <div className='inner'>
          {
            cardList.map((item, index) =>
              <div className={cardActive === index ? 'cardLight' : 'simpleCard'} key={index}>
                {
                  index === 0 ?
                    <div className="cardImgs">
                      <img src={item.cardImg} alt="" />
                    </div> :
                    <img className="cardImg" src={item.cardImg} alt="" />
                }
                {
                  index === 5 ?
                    <img src={item.img} className="hippoCard1" alt="" /> :
                    <img src={item.img} className="hippoCard" alt="" />
                }
                <div className="number">
                  {item.priceInGame} &nbsp;
                  <span className="sol">{item.unit}</span>
                </div>
                <div className="value">
                  {
                    index === 0 ?
                      <img src={item.logo} className="meFive" alt="" /> :
                      <img src={item.logo} className="me" alt="" />
                  }
                  {item.recyclingPrice}
                </div>
              </div>
            )
          }
        </div>
        <div className='second'>
          <i></i><div className="middle">The Hyper Hippo（HHJC）will be drawn.</div>
        </div>
        <div className='cardBottom'>
          <div className="total">
            {
              issuanceObj ?
                (issuanceObj.total - issuanceObj.sold) + '/' + issuanceObj.total : '0/0'
            }
            <RedoOutlined onClick={refreshData} className="refresh" />
          </div>
          {/*  */}
        </div>
      </div>
      <div className='slider'>
        <Slider step={null} marks={marks} ticks defaultValue={33} onChange={getQuantity} />
      </div>
    </div>
  )
}

