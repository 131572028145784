const getConfig = () => {
    const baseConfig = {
        projectId: 'eed0fc59-4c5b-4e3c-bc92-57f9d60c7a8c',
        clientKey: 'cZF2o2AcZ94PlxDLbmPINqYPZyz8j790CHunLd91',
        appId: '16eb5e9a-0b3c-4b62-b1a8-be2c86929cfc',
        signMessage: "Welcome to Soga",
        sellUrl: 'https://soga-2.gitbook.io/q-and-a-of-soga/how-to-list',
        discordUrl: "https://discord.com/invite/yqa229h7tZ",
        twitterUrl: 'https://twitter.com/GametaHippo',
        twitterHomeUrl:'https://twitter.com/',
        youtuBeUrl: "https://youtu.be/6hjDs9vUyDY",
        gametaUrl: "https://www.gameta.pro/",
        elementAccountUrl:"https://www.element.market/account",
        gitbookUrl:"https://soga-2.gitbook.io/q-and-a-of-soga/",
        gametaHomeUrl:'https://www.gameta.pro/#/'

    }

    if (process.env.REACT_APP_ENV === "development") {
        return {
            ...baseConfig,
            baseURL: '/api',
            chainIds: [56, 97],
            chains: [
                {
                    name: "BSC",
                    id: 56,
                }
                , {
                    name: 'BSC',
                    id: 97
                },
            ],
            refreshTokenTime: 1000 * 60 * 10    //10分钟刷一次token
        }
    } else if (process.env.REACT_APP_ENV === "test") {
        return {
            ...baseConfig,
            baseURL: '/api/v1',
            chainIds: [56, 97],
            chains: [
                {
                    name: "BSC",
                    id: 56,
                }
                , {
                    name: 'BSC',
                    id: 97
                },
            ],
            refreshTokenTime: 1000 * 60 * 10   //10分钟刷一次token
        }
    } else {
        return {
            ...baseConfig,
            baseURL: 'https://api.soga.io/api/v1',
            chainIds: [56],
            chains: [
                {
                    name: "BSC",
                    id: 56,
                }
            ],
            refreshTokenTime: 1000 * 60 * 60 * 4      //4个小时刷一次token
        }
    }
}

export const config = getConfig();

export const hippoToPageMap=[{
    phase:52,
    pageUrl:"/hippoclub"
}]