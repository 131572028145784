import './index.scss'
import snowImg from '@/assets/images/home/snow.png'
import snowMan from '@/assets/images/home/snowman.png'
import zebraBox from '@/assets/images/home/christmasBox.png'
import tableImg from '@/assets/images/home/table.png'
import { useEffect, useState } from 'react'
import textImg from '@/assets/images/home/text.png'
import leftImg from '@/assets/images/home/leftLine.png'
import rightImg from '@/assets/images/home/rightLine.png'
import sunImg from '@/assets/images/home/sun.png'
import giftImg from '@/assets/images/home/gifts.png'
import Footer from '@/components/pc/pcFooter'
import { RightOutlined } from '@ant-design/icons'
import AwardDialog from '../awardDialog'
import RecordDialog from '../recordDialog'
// import SwiperCore from 'swiper/core' // 引入核心插件和自动播放组件
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { ActivitiesType, getActivities_func, awardLotteryCount_func, getNFTQueryStatus_func, awardLottery_func } from '@/features/activitiesClice'
import { useAccount, useConnectModal } from '@particle-network/connect-react-ui'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import moment from 'moment'
import hippo from '@/assets/images/home/hippo@2x.png'
// import TipsDialog from '@/components/pc/tipsDialog'
import { useNavigate } from 'react-router-dom'
import lingdangGif from '@/assets/images/home/lingdang.gif'

const timeFormer = "YYYY.MM.DD HH:mm"
const remarkList = [
  'Exclusive VIP activity (having the opportunity to get Hippo NFT airdrop)',
  'Cultural and creative products around Hippo official entity',
  'Offline party for global Hippo holders',
  'Brand cooperation resources',
  'Air drop on the white list of cooperative project parties'
]

function Index() {
  const [showAwardDialog, setShowAwardDialog] = useState(false);
  const [awardData, setAwardData] = useState(null);
  const [showRecordDialog, setShowRecordDialog] = useState(false);
  // const [showTipsDialog, setShowTipsDialog] = useState(false);
  // const [tipsReamrk, setTipsReamrk] = useState('');
  // const [tipsBtns, setTipsBtns] = useState('');
  const [raffleNumber, setRaffleNumber] = useState(0);
  const account = useAccount()
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const connectKit:any = useConnectModal();

  const [selectedContract, setSelectedContract] = useState<any>(null);


  const { chain, contractBalanceList, activitiesInfo2,token }: {
    chain: any,
    activitiesInfo2: any,
    contractBalanceList: any[]
    token:any
  } = useSelector((state: RootState) => {
    return {
      chain: state.globalModule.chain,

      activitiesInfo2: state.activitiesModule.activitiesInfo2,
      contractBalanceList: state.activitiesModule.contractBalanceList,
       token:state.loginModule.token
    }
  })
  function award() {
    dispatch(awardLottery_func(selectedContract.tokenId, (data: any) => {
      // console.log(data, "=======awardLottery_func==")
      setAwardData(data);
      setShowAwardDialog(true)
    }))
    // setShowAwardDialog(true)
  }
  function closeAwardDialog(type: number) {
    getAwardLotteryCount(selectedContract.tokenId)
    if (type === 1) {
      setShowRecordDialog(true)
    } else {
      setShowAwardDialog(false)
    }
  }


  function record() {
    setShowRecordDialog(true)
  }
  function closeRecordDialog() {
    setShowRecordDialog(false)
  }

  // 关闭tips提示框
  // function closeTipsDialog() {
  //   setShowTipsDialog(false)
  // }

  useEffect(() => { 
     //-1 无nft  0 初始化，1 minting中 2 mint 完成  3 mint失败
     dispatch(getNFTQueryStatus_func((data:any)=>{        
      if(data === 2) {
        dispatch(getActivities_func(ActivitiesType.luckDrawActivity, chain?.id, account?[account]:[], (contractAddress:any) => {
          if(contractAddress === 0) {
            navigate('/hippovip')
          }
        }))
      } else {
        navigate('/hippovip')
      }
    }))
    if (account && chain?.id && token) {
     
    }else if(!account && !token){
      connectKit.openConnectModal();
    }
  }, [account, chain?.id,token]);


  useEffect(() => { 
    if (contractBalanceList && contractBalanceList.length > 0) {
      setSelectedContract(contractBalanceList[0]) 
    }
  }, [contractBalanceList])


  useEffect(() => {
    if (selectedContract?.tokenId && account && chain?.id && token) { 
      getAwardLotteryCount(selectedContract?.tokenId)
    }
  }, [selectedContract?.tokenId,account, chain?.id,token])

  const getAwardLotteryCount = (tokenId: any) => {
    dispatch(awardLotteryCount_func(tokenId, (data: any) => {
      setRaffleNumber(data);
    }))
  }
  return (
    <div className="christmas">
      <div className='inner'>
        <div className='activeTop'>
          <div className='left'>

            <div className='vip'>
              <div className='dis'>Hippo VIP</div>
              <div className='back'>Welcome<br />Back！</div>
            </div>
            <img src={snowImg} className="snow" alt="" />
            <img src={snowMan} className="man" alt="" />
          </div>
          <div className='right'>
            {contractBalanceList.length == 0 ? <div className='swiper-nft swiper-no-swiping'>
              <div className="swiper-slide zebra" style={{ margin: 'auto' }}>
                <img src={hippo} className="nftsImg" alt="" />
              </div>
            </div> : <Swiper
              modules={[Navigation]}
              className="swiper-nft swiper-no-swiping"
              spaceBetween={0}
              slidesPerView={1}
              loop
              navigation
              onSlideChange={(swiper) => {
                setSelectedContract(contractBalanceList[swiper.realIndex])
              }}
            >
              {
                contractBalanceList.map((item, index) =>
                  <SwiperSlide className="swiper-slide zebra" key={index}>
                    <img src={`https://www.soga.io/metadata/spec-nfts/hcv/images/${item.tokenId}.png` } className="nftsImg" alt="" />
                  </SwiperSlide>
                )
              }
            </Swiper>}

            <img src={tableImg} className="table1" alt="" />
            <img src={lingdangGif} onClick={() => {navigate('/hippoclub')}} className='lingdang' alt="" />
          </div>
        </div>
        <div className='activeBottom' style={{ height: '1300px' }}>
          <div className='title'>
            <img src={leftImg} alt="" />
            <img src={textImg} alt="" />
            <img src={rightImg} alt="" />
          </div>
          <div className='receive'>During the campaign period, Hippo VIP can claim rewards every day<br />（{activitiesInfo2 && moment(activitiesInfo2.startTime).format(timeFormer)} - {activitiesInfo2 && moment(activitiesInfo2.endTime).format(timeFormer)}）</div>
          <div className='iconPie'>
            <div className='pie'></div>
            <div className='rece'>
              <div className='number'>Number of Claim:  <span>{raffleNumber}</span></div>
              <div className='record' onClick={record}>Awards records <RightOutlined /></div>
            </div>
            <div className='pie'></div>
          </div>
          <Swiper
            modules={[Navigation]}
            className="swiper-container1 swiper-no-swiping"
            spaceBetween={-34}
            slidesPerView={4}
            loop
            navigation
          >
            {
              activitiesInfo2 && activitiesInfo2.awards.map((item: any, index: any) =>
                <SwiperSlide className="swiper-slide prizeBox" key={index}>
                  <div className='box'></div>
                  <img src={item.image} className="kuang" alt="" />
                  <div className='prizeBtn'>{item.name}</div>
                </SwiperSlide>
              )
            }
          </Swiper>
          {
            raffleNumber <= 0?
            <div className='extractBtn disabled'>Claim</div>:
            <div className='extractBtn' onClick={award}>Claim</div>
          }
          <img src={giftImg} className='gift' alt="" />
          <div className='rule'>
            {
              remarkList.map((item, index) =>
                <div key={index}><img src={sunImg} alt="" /> {item} <img src={sunImg} alt="" /></div>
              )
            }
          </div>
        </div>
        <Footer />
      </div>
      <AwardDialog showAwardDialog={showAwardDialog} data={awardData} closeAwardDialog={closeAwardDialog} />
      <RecordDialog showRecordDialog={showRecordDialog} closeRecordDialog={closeRecordDialog} />
      {/* <TipsDialog showTipsDialog={showTipsDialog} closeTipsDialog={closeTipsDialog} tipsReamrk={tipsReamrk} tipsBtns={tipsBtns} /> */}
    </div>
  )
}

export default Index