import './index.scss';
import notOpenImg from '../../../assets/images/home/not-open.png'

function pcTrade() {
  return (
    <div className='pcTrade'>
      <div className="tradeContent">
        <img src={notOpenImg} alt="" />
        <div className="remark">The marketplace is under construction, follow our twitter<br />(<span onClick={() => {window.open('https://twitter.com/GametaHippo')}}>https://twitter.com/GametaHippo</span>)to receive first-hand progress updates.</div>
        {/* {'Coming soon...'} */}
      </div>
    </div>
  )
}

export default pcTrade