import './index.scss'
import { Modal, Pagination } from 'antd';
import React, { useState, useEffect } from "react";
import selectImg from '../../../assets/images/result/select.png'
import HistoryDialog from '../historyDialog/index'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux";
import { get_NFTs_func, setCheckData, setMHTotalPrice, setHMTotalPrice } from '../../../features/orderClice.ts'
import { useAccount, useParticleProvider } from "@particle-network/connect-react-ui";
import TipsDialog from '../tipsDialog/index'
import data_loading from '@assets/images/home/Date-loading.png';
import OpenSea_Full_Logo from '@assets/images/home/element_logo.png';
import wenhaoImg from '@assets/images/home/wenhao.png';
import { config } from '@/config'

function HowToSell() {
  return <a className='how_to_sell' target="_blank" href={config.sellUrl}> <img src={wenhaoImg} />  How to sell</a>
}
function CollectionDialog(props) {
  const { chainId, checkData, hmNftList, HMTotalPrice, MHTotalPrice } = useSelector(state => {
    return {
      chainId: state.globalModule.chain?.id,
      checkData: state.orderModule.checkData,
      hmNftList: state.orderModule.NFTs,
      HMTotalPrice: state.orderModule.HMTotalPrice,
      MHTotalPrice: state.orderModule.MHTotalPrice
    }
  })
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0)
  const [newHm, setNewHm] = useState(null)
  const [current, setCurrent] = useState(1)
  const [showHistoryDialog, setShowHistoryDialog] = useState(false)
  const [bnbPrice, setBnbPrice] = useState(0)
  const [showTipsDialog, setShowTipsDialog] = useState(false);
  const [selectPrice, setSelectPrice] = useState([])
  const [tipsReamrk, setTipsReamrk] = useState('');
  const [tipsBtns, setTipsBtns] = useState('');
  const provider = useParticleProvider();
  const account = useAccount();
  // 点击关闭当前收集弹框
  function handleCancel(type) {
    props.closeCollectionDialog(type)
    onChange(1, 16)
  }
  function showTips(type) {
    if (type === 1) {
      // 上架成功文案
      setTipsReamrk(`Successfully submitted. The BNB will arrive at the wallet in 5-15 minutes.`)
      setTipsBtns('Confirm')
    } else if (type === 2) {
      // 上限文案
      setTipsReamrk(`The upper limit of single sale has been reached.`)
      setTipsBtns('Confirm')
    } else if (type === 0) {
      // 上架失败文案
      setTipsReamrk(`Failed to submit. Please do not check the sold NFTs.`)
      setTipsBtns('Confirm')
    }
    setShowTipsDialog(true)
  }
  // 关闭tips提示框
  function closeTipsDialog() {
    if(tipsReamrk==`Successfully submitted. The BNB will arrive at the wallet in 5-15 minutes.`){
      refreshData()
    }
    setShowTipsDialog(false)
  }
  function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return <div className='nextBtn'>Last page</div>;
    }
    if (type === 'next') {
      return <div className='nextBtn'>Next page</div>;
    }
    return originalElement;
  }
  // 河马设置手动分页器
  function onChange(page, pageSize, data) {
    setCurrent(page)
    let start = pageSize * page - 16
    let end = pageSize * page
    const newData = data === undefined ? hmNftList : data
    if (newData.length <= pageSize) {  //这种情况为只有20条或者20条以下数据的情况
      setNewHm([...newData])
    } else {  //这种情况为大于20条数据
      if (pageSize * page >= newData.length) {//用户点击的那一页是最后一页的数据，比如有49条数据，用户点击第3页，第二3就应该显示最后9条的数据
        setNewHm([...newData.slice(start)])
      } else { //用户点击的那一页不是最后一页的数据，比如有49条数据，用户点击第二页，第二页就应该显示20至40条的数据
        setNewHm([...newData.slice(start, end)])
      }
    }
  }
  useEffect(() => {
    if (props.showCollectionDialog) {
      dispatch(setMHTotalPrice(0))
      dispatch(setHMTotalPrice(0))
      dispatch(setCheckData([]))
      setTotal(0)
      setBnbPrice(0)
      init && init()
    }
  }, [props.showCollectionDialog])
  function init() {
    setNewHm(null)
    if (account) {
      dispatch(get_NFTs_func(chainId, [account], (data) => {
        // setSelectPrice(data)
        // let num = 0
        // data.forEach(item => {
        //   num += Number(item.recyclingPrice)
        // })
        // setBnbPrice(num.toFixed(4))
      }))
    }
  }
  useEffect(() => {
    if (account) {
      onChange(1, 16, hmNftList)
      setTotal(hmNftList.length)
    } else {
      setNewHm(null)
    }
  }, [hmNftList,account])
  function checked(tokenId, stat, status) {
    console.log(status);
    if (status) {
      return
    }

    let selectData = [...checkData]
    if (stat) {
      const index = selectData.indexOf(tokenId)
      if (index !== -1) {
        selectData.splice(index, 1)
        dispatch(setCheckData(selectData))
        priceTotal(selectData)
      }
    } else {
      if (selectData.length < 30) {
        selectData.push(tokenId)
        dispatch(setCheckData(selectData))
        priceTotal(selectData)
      } else if (selectData.length >= 30) {
        // 提示选中上限
        showTips(2)
      }
    }
  }
  function priceTotal(selectData) {
    let num = 0
    selectPrice.forEach(item => {
      selectData.forEach(ele => {
        if (item.mint === ele) {
          num += Number(item.recyclingPrice)
        }
      })
    })
    setBnbPrice(num.toFixed(4))
  }
  // 打开history弹框
  function showHistory() {
    setShowHistoryDialog(true)
  }
  // 关闭history弹框
  function closeHistroyDialog() {
    setShowHistoryDialog(false)
  }
  // 上架所选河马
  function sellHm() {
    window.open(config.elementAccountUrl)
    // dispatch(seaport_listing_func(account, chainId, checkData, provider, (data, type) => {
    //   showTips(type)
    // }))
  }
  // 刷新收集页面数据
  function refreshData() {
    init()
  }
  return (
    <Modal className='collection' footer={null} title={null} open={props.showCollectionDialog} maskClosable={false} keyboard={false} wrapClassName='collectionDialog' onCancel={handleCancel}>
      <div className="dialogContent">
        <div className="headerBg"></div>
        <div className="dialogHeader">MY COLLection</div>
        <div className="price">Gaming Value:  <span>{MHTotalPrice}</span> USDT, Floor Price: <span>{HMTotalPrice}</span> BNB</div>
        <HowToSell />
        {/* <div className='historyBtn' onClick={showHistory}>History</div> */}
       
        <div className={`hm ${newHm?.length === 0?'data_loading_hm':''}`}>
          {
            account ?
              <div>
                {
                  newHm == null ?
                    <LoadingOutlined className='loadIcon' /> :
                    <div>
                      {
                        newHm.length === 0 ?
                          <div className='noData data_loading'>
                            <img src={data_loading} />
                            <label>Data loading failed, please try again.</label>
                          </div> :
                          <div>
                            {
                              newHm.map((item, index) =>
                                <div key={index}>
                                  {
                                    item.manghe === 1 ?
                                      <div className="mhList">
                                        <img src={item.image} className="hmImg" alt="" />
                                        <div className="hmName">{item.name}</div>
                                        <div className="sell">Gaming Value:<br />{item.game_value + ' '}USDT</div>
                                      </div> :
                                      // onClick={() => checked(item.tokenId, checkData.includes(item.tokenId), item.status)}
                                      <div className="hmList" >
                                     
                                        {
                                          item.status && <div className="listingDiv">Listing</div>
                                        } 
                                        <img src={item.image} className="hmImg" alt="" />
                                        <img src={item.bgImage} className="bgImg"  alt="" />
                                        <div className="hmName">{item.name}</div>
                                        <div className="sell">Floor Price:<br />{item.recyclingPrice + ' '}BNB</div>
                                      </div>
                                  }
                                </div>
                              )
                            }
                          </div>
                      }
                    </div>
                }
              </div> :
              <div>
                <div className='noData1'>No data</div>
              </div>
          }
        </div>
        {
          newHm !== null &&
          <div>
            {
              newHm.length !== 0 ?
                <div>
                  <Pagination
                    className='pagination'
                    defaultCurrent={1}
                    defaultPageSize={16}
                    current={current}
                    total={total}
                    itemRender={itemRender}
                    onChange={onChange}
                    showSizeChanger={false}
                  />
                  {/* <div className='recovery'>You have checked <span>{checkData.length}</span> NFTs, and the total price : <span>{bnbPrice}</span> BNB.</div> */}
                  <div className='sellAndKeepBtns'>
                  <div className='sellBtn' onClick={sellHm}>  
                        <img src={OpenSea_Full_Logo} /> LIST ON ELEMENT
                      </div>
                        
                    <div className='keepBtn' onClick={handleCancel} >KEEP HOLDING</div>
                  </div>
                </div> :
                <div className='noDataBtns' onClick={refreshData}>
                  Retry
                </div>
            }
          </div>

        }

      </div>
      <HistoryDialog showHistoryDialog={showHistoryDialog} closeHistroyDialog={closeHistroyDialog} />
      <TipsDialog showTipsDialog={showTipsDialog} closeTipsDialog={closeTipsDialog} tipsReamrk={tipsReamrk} tipsBtns={tipsBtns} />
    </Modal>
  )
}

export default CollectionDialog