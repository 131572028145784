import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import Index from "./views/index";
import PcView from "./views/pcView"
import PcFiveView from './views/pcView/five'
import PcTwoView from './views/pcView/two'
import Home from './views/Home';

import H5 from '@views/h5View/index'
import H5Home from '@views/h5View/home';
import H51of2Home from '@views/h5View/1of2Home';
import LuckyMint from '@views/h5View/luckyMint';
import TopWin from '@views/h5View/topWin';
import GameToEarn from '@views/h5View/gameToEarn';
import Rules from '@views/h5View/rules';
import AddPurchase from '@views/h5View/addPurchase';
import Trade from '@views/h5View/trade';
import Collection from '@views/h5View/collection';
import Congratulations from '@views/h5View/congratulations'
import Congratulations2 from '@views/h5View/congratulations2'
import H5HippoList from './views/h5View/hippoList'
import H5Christmas from './views/h5View/christmas'

import { ModalProvider } from '@particle-network/connect-react-ui';
import { Chain, metaMask, walletconnect } from '@particle-network/connect';
import { config } from '@/config';
import useSetBaseFontSize from './hooks/useSetBaseFontSize';
import Balance from './views/h5View/h5Balance';
import WhiteList from './views/h5View/whiteList'
import H5Rules from './views/h5View/h5Rules'
import BnbActivity from './views/pcView/bnbActivity';
import Christmas from './views/pcView/christmas'
import Activity from './views/pcView/activity'
import ComingSoon from './views/pcView/comingSoon'
import HippoList from './views/pcView/hippoList'
import PcTrade from './views/pcView/trade'
import useRefreshToken from './hooks/useRefreshToken';

function App() {
  useSetBaseFontSize();
  useRefreshToken();


  const type = sessionStorage.getItem('issuanceType');

  const isMobile = localStorage.getItem('isMobile') 
  if (process.env.REACT_APP_ENV === 'test' && window.location.host === "soga.ge81.com" && isMobile == '1') {
    window.location.href = "https://m.soga.ge81.com/"
  } else if (process.env.REACT_APP_ENV === 'test' && window.location.host === "m.soga.ge81.com" && isMobile == '0') {
    window.location.href = "https://soga.ge81.com/"
  } else if (process.env.REACT_APP_ENV === 'production' && window.location.host === "www.soga.io" && isMobile == '1') {
    window.location.href = "https://m.soga.io/"
  } else if (process.env.REACT_APP_ENV === 'production' && window.location.host === "m.soga.io" && isMobile == '0') {
    window.location.href = "https://www.soga.io/"
  }

  if (process.env.REACT_APP_ISMOBILE === 'true') {
    return <div className="App">
      <ConfigProvider locale={zhCN}>

        <ModalProvider
          walletSort={['Wallet', 'Particle Auth']}
          options={{
            projectId: config.projectId,
            clientKey: config.clientKey,
            appId: config.appId,
            chains: config.chains as Chain[],
            wallets: [metaMask({ qrcode: false }), walletconnect()],
          }}
          theme={(localStorage.getItem("dapp_particle_theme") ?? "light") as "light" | "auto" | "dark"}
        >
          <BrowserRouter>
            {/* <Suspense fallback={<CLoading visible={true} />}> */}
            <Routes>
              <Route path='/' element={<H5 />} >
                <Route path="/" element={<H5HippoList />} />
                <Route path="/hippomint" element={<H5Home />} />
                <Route path="/hippomintmini" element={<H51of2Home />} />
                <Route path="/hippoclub" element={<H5Christmas />} />
                <Route path="/mint" element={<LuckyMint />} />
                <Route path="/topWin" element={<TopWin />} />
                <Route path='/trade' element={<Trade />}></Route>
                <Route path="/games" element={<GameToEarn />} />
                <Route path="/rules" element={<Rules />} />
                <Route path="/h5Rules" element={<H5Rules />} />
                <Route path="/addPurchase" element={<AddPurchase />} />
                <Route path="/balance" element={<Balance />}></Route>
                <Route path="/collection" element={<Collection />} />
                <Route path="/Congratulations" element={<Congratulations />} />
                <Route path='/whiteList' element={<WhiteList />}></Route>
                <Route path="/" element={<Navigate replace to="/" />} />
              </Route>
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
            {/* </Suspense> */}
          </BrowserRouter>
        </ModalProvider>
      </ConfigProvider>
    </div>
  } else {
    return <div className="App">
      <ConfigProvider locale={zhCN}>

        <ModalProvider
          walletSort={['Wallet', 'Particle Auth']}
          options={{
            projectId: config.projectId,
            clientKey: config.clientKey,
            appId: config.appId,
            chains: config.chains as Chain[],
            wallets: [metaMask({ qrcode: false }), walletconnect()],
          }}
          theme={(localStorage.getItem("dapp_particle_theme") ?? "light") as "light" | "auto" | "dark"}
        >
          <BrowserRouter>
            {/* <Suspense fallback={<CLoading visible={true} />}> */}
            <Routes>
              <Route path='/' element={<Index />} >
                <Route path='/' element={<HippoList />} />
                <Route path='hippoclub' element={<PcView />} />
                <Route path='hippomint' element={<PcFiveView />}></Route>
                <Route path='hippomintmini' element={<PcTwoView />} />
                <Route path='worldcup/nft' element={<BnbActivity />} />
                <Route path='activity' element={<Activity />} />
                <Route path='hippovip' element={<Christmas />} />
                <Route path='home' element={<Home />} />
                <Route path='trade' element={<PcTrade />} />
                <Route path="/" element={<Navigate replace to="/" />} />
              </Route>
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
            {/* </Suspense> */}
          </BrowserRouter>
        </ModalProvider>
      </ConfigProvider>
    </div>
  }


};

export default App;
