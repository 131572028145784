import './index.scss'
import ttImg from '@/assets/images/home/icon_tt@2x.png'
import disccord from '@/assets/images/home/icon_disccord@2x.png'

function PcFooter(params) {
  return (
    <div className='pcFooter'>
      <div className='footerInner'>
        <div className="footerSoga">
          <div>© 2022 SOGA. </div>
        </div>
        <div className="footerIcon">
          <img src={ttImg} onClick={() => {window.open('https://twitter.com/GametaHippo')}} />
          <img src={disccord} onClick={() => {window.open('https://discord.com/invite/yqa229h7tZ')}} />
        </div>
      </div>
    </div>
  )
}

export default PcFooter