import './index.scss'
import logoImg from '@/assets/images/home/logo.png'
import ttImg from '@/assets/images/home/icon_tt@2x.png'
import disccord from '@/assets/images/home/icon_disccord@2x.png'
import {config} from '@/config'

function Support() {
  function goTwitter() {
    window.open(config.twitterUrl)
  }
  function goDiscord() {
    window.open(config.discordUrl)
  }
  return (
    <div className='support'>
      <img className="logo" src={logoImg} alt="" />
        <div className="remark">Unfortunately, we are unable to provide<br/>services to the users in restricted countries/regions.</div>
        <div className="icon">
          <img onClick={goTwitter} src={ttImg} alt="" />
          <img onClick={goDiscord} src={disccord} alt="" />
        </div>
    </div>
  )
}

export default Support