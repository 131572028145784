import './index.scss'
export enum H5Body {
    type1 = 1,
    type2 = 2
}
export default function Index({ type, children,className }: {
    type?: H5Body,
    children: any,
    className?:string
}) {
    return <div id="h5Body" className={`h5body ${type===H5Body.type2 && 'type2'} ${className || ''}`}>
        {children}
    </div>
}