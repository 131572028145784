import { axios } from '@utils/request' 
import Qs from 'qs'


interface IRegister {
    wallet: string,
    signature?: string,
    channelCode?: string,
    networkName?: string
}

export function register(d: IRegister) {
    if (d.networkName) {
        delete d.networkName
    }
    return axios({
        url: `/user:register`,
        method: 'post',
        data: Qs.stringify(d),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}


export function refreshToken(){
    return axios({
        url: `/user:refreshToken`,
        method: 'post'
    }) 
}