import './index.scss'
import { Modal } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { getAwardRecordUser_func } from '@features/activitiesClice'
import moment from 'moment' 


const timeFormer = "YYYY.MM.DD HH:mm"

function RecordDialog(props) {
  // const resultList = [{ time: '2022-12-25', img: require('../../../assets/images/home/hippo@2x.png') }, { time: '2022-12-25', img: require('../../../assets/images/home/hippo@2x.png') }, { time: '2022-12-25', img: require('../../../assets/images/home/hippo@2x.png') }, { time: '2022-12-25', img: require('../../../assets/images/home/hippo@2x.png') }, { time: '2022-12-25', img: require('../../../assets/images/home/hippo@2x.png') }, { time: '2022-12-25', img: require('../../../assets/images/home/hippo@2x.png') }]
  function handleCancel() {
    props.closeRecordDialog()
  }

  const dispatch = useDispatch();

  const [list, setList] = useState([])

  useEffect(() => {
    if (props.showRecordDialog) {
      dispatch(getAwardRecordUser_func((data) => { 
        setList(data.list)
      }))
    }
  }, [props.showRecordDialog])
  return (
    <Modal className='record' footer={null} title={null} open={props.showRecordDialog} zIndex={2} keyboard={false} wrapClassName='recordDialog' maskClosable={false} onCancel={handleCancel}>
      <div className='recordContent'>
        <div className='header'></div>
        {
          list.length === 0 ?
            <div className='no'>No data</div> :
            <div className='detailContent'>
              {
                list.map((item, index) =>
                  <div className='detail' key={index}>
                    <div>{moment(item.createTime).format(timeFormer)}</div>
                    <div className='box'>
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                )
              } 
            </div>
        }
      </div>
    </Modal>
  )
}

export default RecordDialog