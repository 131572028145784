import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@store/index';
import { isRestrictedCountry, issuance, getHyperMint, getUrls, getPrice, getUserOrders, getListOnSale, getListNotForSale, quantityInStock, getIssuanceItems, getWhiteList } from '../api/index'

const GlobalClice = createSlice({
  name: 'GlobalModule',
  initialState: {
    isRestrictedCountry: false,
    issuance: null,
    hyperMintList: [],
    urls: '',
    price: '',
    userOrdersList: [],
    chain: null,
    balance: 0,
    tradIndexStat: false,
    quantity: 5,
    isMobile: false,
    itemInfo: {},
    whiteList: [],
    loadStat: false,
    loadType: '1',
    activeStat: 0
  },
  reducers: {
    setiIsRestrictedCountry(state, { payload }) {
      state.isRestrictedCountry = payload;
    },
    setIssuance(state, { payload }) {
      state.issuance = payload
    },
    setHyperMint(state, { payload }) {
      state.hyperMintList = payload
    },
    setUrls(state, { payload }) {
      state.urls = payload
    },
    setPrice(state, { payload }) {
      state.price = payload
    },
    setUserOrders(state, { payload }) {
      state.userOrdersList = payload
    },
    seChain(state, { payload }) {
      state.chain = payload
    },
    setBalance(state, { payload }) {
      state.balance = payload
    },
    setTradIndexStat(state, { payload }) {
      state.tradIndexStat = payload
    },
    setQuantity(state, { payload }) {
      state.quantity = payload
    },
    setIsMobile(state, { payload }) {
      state.isMobile = payload
    },
    setItemInfo(state, { payload }) {
      state.itemInfo = payload
    },
    setWhiteList(state, { payload }) {
      state.whiteList = payload
    },
    setLoadStat(state, { payload }) {
      state.loadStat = payload
    },
    setLoadType(state, { payload }) {
      state.loadType = payload
    },
    setActiveStat(state: any, { payload }) {
      state.activeStat = payload
      sessionStorage.setItem('activeStat', state.activeStat)
    }
  },
});

export const { setiIsRestrictedCountry, setIssuance, setHyperMint, setUrls, setPrice, setUserOrders, seChain, setBalance, setTradIndexStat, setQuantity, setIsMobile, setItemInfo, setWhiteList, setLoadStat, setLoadType, setActiveStat } = GlobalClice.actions;

export const isRestrictedCountry_Func = (cb?: Function): AppThunk => async (dispatch, getState) => {
  const res: any = await isRestrictedCountry();
  if (res.success && res.data === false) {
    cb && cb(false)
  } else if (res.success && res.data) {
    cb && cb(true)
  }
}

export const issuance_Func = (cb?: Function): AppThunk => async (dispatch, getState) => {
  const issuanceId = sessionStorage.getItem('issuanceId')
  if (issuanceId) {
    const res: any = await issuance(issuanceId);
    if (res.success) {
      dispatch(setIssuance(res.data))
    }
  }
}

export const getUrls_Func = (cb?: Function): AppThunk => async (dispatch, getState) => {
  const res: any = await getUrls();
  if (res.success) {
    dispatch(setUrls(res.data.metadata))
  }
}

export const getPrice_Func = (cb?: Function): AppThunk => async (dispatch, getState) => {
  const res: any = await getPrice();
  if (res.success) {
    dispatch(setPrice(res.data.BNBUSDT.price))
  }
}

export const getUserOrders_Func = (cb?: Function): AppThunk => async (dispatch, getState) => {
  const res: any = await getUserOrders({ pageStart: 1, pageSize: 999 });
  if (res.success) {
    const arr: any = []
    res.data.list.forEach((item: any, index: any) => {
      let obj = {
        quantity: '',
        status: '',
        totalPrice: '',
        createTime: '',
        time: '',
        id: '',
        actualPrice: ''
      }
      obj.id = item.id
      obj.quantity = item.quantity
      obj.status = item.status
      obj.totalPrice = item.totalPrice
      obj.createTime = item.createTime
      obj.actualPrice = item.actualPrice
      let createTime = item.createTime
      const year = new Date(createTime).getFullYear() < 10 ? '0' + new Date(createTime).getFullYear() : new Date(createTime).getFullYear()
      const month = new Date(createTime).getMonth() + 1 < 10 ? '0' + (new Date(createTime).getMonth() + 1) : new Date(createTime).getMonth() + 1
      const date = new Date(createTime).getDate() < 10 ? '0' + new Date(createTime).getDate() : new Date(createTime).getDate()
      const h1 = new Date(createTime).getHours() < 10 ? '0' + new Date(createTime).getHours() : new Date(createTime).getHours();
      const m1 = new Date(createTime).getMinutes() < 10 ? '0' + new Date(createTime).getMinutes() : new Date(createTime).getMinutes();
      const s1 = new Date(createTime).getSeconds() < 10 ? '0' + new Date(createTime).getSeconds() : new Date(createTime).getSeconds();
      obj.time = year + '.' + month + '.' + date + ' ' + h1 + ':' + m1 + ':' + s1
      arr.push(obj)
    });
    dispatch(setUserOrders(arr))
  }


}
export const getListOnSale_func = (cb?: Function): AppThunk => async (dispatch, getState) => {
  const result: any = await getListOnSale();
  const result2: any = await getListNotForSale();
  let newList: any = [];
  if (result && result.code == "0") {
    const saleList = result.data || [];
    newList = saleList;
    if (result2 && result2.code == "0") {
      newList = saleList.concat(result2.data.list)
    }
    cb && cb(newList)
  }
}

export const getInfo_func = (issuanceId: any, cb?: Function): AppThunk => async (dispatch, getState) => {
  const result: any = await getIssuanceItems(issuanceId);
  if (result.success) { 
    const list = result.data.sort((left: any, right: any) => {
      return Number(right.recyclingPrice) - Number(left.recyclingPrice)
    })
    cb && cb(list)
  } 
}

export const getHyperMint_Func = (cb?: Function): AppThunk => async (dispatch, getState) => {
  const issuanceId = sessionStorage.getItem('issuanceId')
  if (issuanceId) {
    const res: any = await getHyperMint({ 'pageSize': 100, issuanceId: issuanceId });
    if (res.success) {
      dispatch(setHyperMint(res.data))
    }
  }
}

export const getWhiteList_Func = (cb?: Function): AppThunk => async (dispatch, getState) => {
  const res: any = await getWhiteList();
  const itemInfo: any = getState().globalModule.itemInfo;
  if (res.success) {
    const info = res.data.filter((item: any, index: number) => {
      return item.phase == itemInfo?.phase
    })
    if (itemInfo) {
      const arr = info.map((item: any) => {
        if (item.totalPrice === '0') {
          item.title = 'Free'
          item.remarklist = ['No charge for MINT', `<span>Scope of application: ${itemInfo.type}</span>`, `<span>MINT option: ${item.mintQuantity} MINT</span>`, `<div class='label'>MINT Total Price: ${item.totalPrice} (<span>${Number(itemInfo.price) * item.mintQuantity}</span>) BNB/MINT</div>`]
        } else if (Number(item.totalPrice) > 0 && item.redressFlag === true) {
          item.title = 'Breakeven'
          item.remarklist = [`If the repurchase amount is less than the payment amount, the official will compensate for the loss`, `<span>Scope of application: ${itemInfo.type}</span>`, `<span>MINT option: ${item.mintQuantity} MINT</span>`, `<div class='label'>MINT Total Price: ${item.totalPrice} (<span>${Number(itemInfo.price) * item.mintQuantity}</span>) BNB/MINT</div>`]
        } else if (Number(item.totalPrice) > 0 && item.redressFlag === false) {
          item.title = 'Discount'
          item.remarklist = [`Use discount price to participate in MINT`, `<span>Scope of application: ${itemInfo.type}</span>`, `<span>MINT option: ${item.mintQuantity} MINT</span>`, `<div class='label'>MINT Total Price: ${item.totalPrice} (<span>${Number(itemInfo.price) * item.mintQuantity}</span>) BNB/MINT</div>`]
        }
        return item
      })
      dispatch(setWhiteList(arr))
    }
  }
}

// getWhiteList



export default GlobalClice.reducer;