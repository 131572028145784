import { memo, useEffect, useState } from "react"
import './index.scss'


const Index = () => {

    const [lightStat, setLightStat] = useState(false)
    useEffect(() => {
        const timer = setTimeout(() => {
            setLightStat(!lightStat)
        }, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [lightStat])
    return <div className={lightStat ? 'deng1' : "deng2"} />
}

export default memo(Index)