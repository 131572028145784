import './index.scss'
import { Modal } from 'antd';
import bottomImg from '@/assets/images/home/white-bottom.png';
import { useSelector } from 'react-redux';

function WhiteListDialog(props) {
  const { whiteList } = useSelector(state => {
    return {
      whiteList: state.globalModule.whiteList
    }
  })

  function handleCancel() {
    props.closeWhiteListDialog()
  }

  return(
    <Modal footer={null} title={null} open={props.showWhiteListDialog} keyboard={false} maskClosable={false} wrapClassName='whiteListDialog' onCancel={handleCancel}>
      <div className='dialogContent'>
        <div className="whiteHeader">whitelist</div>
        <div className='title'>Dear whitelist customers,<br/>you currently enjoy the following privileges:</div>
        <div className='card'>
          {
            whiteList?.map((item, index) => 
              <div className='list' key={index}>
                <div className='listInner'>
                  <div className='whiteTitle'>{item.title}</div>
                  <div className='remark'>
                    {
                      item.remarklist.map((ele, ind)=> 
                        <div key={ind} dangerouslySetInnerHTML={{__html: ele}} />
                      )}
                  </div>
                </div>
                <img src={bottomImg} className='bottom' alt="" />
              </div>  
          )}
        </div>
      </div>
    </Modal>
  )
}

export default WhiteListDialog