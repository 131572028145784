import './index.scss'
import { Modal } from 'antd'
import { useState } from 'react'
import discordImg from '@/assets/images/home/icon-Discord.png'
import { config } from '@/config'

function TipsDialog(props) {
  function handleCancel(type) {
    props.closeTipsDialog(type)
  }
  function openMintList() {
    handleCancel(1)
  }
  function goDiscord() {
    window.open(config.discordUrl)
  }
  return (
    <Modal className='tips' footer={null} title={null} open={props.showTipsDialog} keyboard={false} maskClosable={false} wrapClassName='tipsDialog' getContainer="false" onCancel={handleCancel}>
      <div className='tipsContent'>
        <div className="dialogHeader">TIPs</div>
        <div className="remark">
          {props.tipsReamrk}
        </div>
        {
          props.tipsBtns === '' &&
          <div className='kefu'>
            <img className="img4" src={discordImg} onClick={goDiscord} alt="" />
          </div>

        }
        {
          props.tipsBtns === 'view' &&
          <div className="pay" onClick={openMintList}>
            {props.tipsBtns}
          </div>
        }
        {
          props.tipsBtns === 'Confirm' &&
          <div className="pay" onClick={handleCancel}>
            {props.tipsBtns}
          </div>
        }
      </div>
    </Modal>
  )
}

export default TipsDialog