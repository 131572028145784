import './index.scss'
import React, { useState, useEffect, useRef } from "react";
import { Slider } from 'antd'
import { RedoOutlined } from '@ant-design/icons';
import finger from '../../../assets/images/home/guide.gif'
import RulesDialog from '../rulesDialog/index'
import GameDialog from '../gameDialog/index'
import BalanceDialog from '../balanceDialog/index'
import AddFunddsDialog from "../addFunddsDialog/index";
import LuckListDialog from '../luckListDialog/index'
import ResultDialog from '../resultDialog/index'
import CollectionDialog from '../collectionDialog/index'
import TipsDialog from '../tipsDialog/index'
import { useDispatch, useSelector } from 'react-redux';
import SwiperCore, { Autoplay } from 'swiper/core' // 引入核心插件和自动播放组件
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { create_order_func, seaport_listing_func, get_NFTs_func } from '@features/orderClice';
import { useAccount, useConnectModal } from "@particle-network/connect-react-ui";
import { cancel_order_func, ReasonEnum, payDone_func } from '@features/orderClice';
import DengDiv from '@components/lightDivNew'
import loadWhite from '../../../assets/images/home/load_white.gif'
import { useNavigate } from "react-router-dom";
import FreeWhiteDialog from '../freeWhiteDialog'
import { getInfo_func, getWhiteList_Func, setLoadStat, setLoadType, issuance_Func, getHyperMint_Func } from '@/features/globalClice';
import luckyMintBtn from '@assets/images/home/luckyMintBtn.svg'
import pcHistory from '@assets/images/home/pcHistory.svg'
import pcRules from '@assets/images/home/pcRules.svg'
import floorLogo from '../../../assets/images/home/element_logo.png';
import gamingLogo from '../../../assets/images/home/gameta_logo@2x.png';
import { getImage, getBgImage } from '@utils/stringFormat'

SwiperCore.use([Autoplay])


const dataList = [
  { img: require('../../../assets/images/home/GHC2.gif'), cardImg: require('../../../assets/images/home/uncommon5.png'), priceInGame: '0.1', logo: require('../../../assets/images/home/element_logo.png'), recyclingPrice: 'Floor Price', name: 'Uncommon' },
  { img: require('../../../assets/images/home/GHC3.gif'), cardImg: require('../../../assets/images/home/rare5.png'), priceInGame: '0.4', logo: require('../../../assets/images/home/element_logo.png'), recyclingPrice: 'Floor Price', name: 'Rare' },
  { img: require('../../../assets/images/home/HHJC.gif'), cardImg: require('../../../assets/images/home/hyper5.png'), priceInGame: '5', logo: require('../../../assets/images/home/element_logo.png'), recyclingPrice: 'Floor Price', name: 'HYPER', unit: 'BNB' },
  { img: require('../../../assets/images/home/GHC4.gif'), cardImg: require('../../../assets/images/home/epic5.png'), priceInGame: '1.5', logo: require('../../../assets/images/home/element_logo.png'), recyclingPrice: 'Floor Price', name: 'Epic' },
  { img: require('../../../assets/images/home/GHC1.gif'), cardImg: require('../../../assets/images/home/lucky5.png'), priceInGame: '0.04', logo: require('../../../assets/images/home/element_logo.png'), recyclingPrice: 'Floor Price', name: 'Lucky' },
  { img: require('../../../assets/images/home/GMB1.jpg'), cardImg: require('../../../assets/images/home/mystery5.png'), priceInGame: '10', logo: require('../../../assets/images/home/gameta_logo@2x.png'), recyclingPrice: 'Gaming Value', name: 'MYSTERY' }
]
function CardContent(props) {
  const [cardActive, setCardActive] = useState(0)
  const [fingerStat, setFingerStat] = useState(true)
  const [mintStat, setMintStat] = useState(false)
  const [num, setNum] = useState(180)
  const [quantity, setQuantity] = useState(5)
  const [showRulesDialog, setShowRulesDialog] = useState(false);
  const [showGameDialog, setShowGameDialog] = useState(false);
  const [showBalanceDialog, setShowBalanceDialog] = useState(false);
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [showCollectionDialog, setShowCollectionDialog] = useState(false);
  const [showTipsDialog, setShowTipsDialog] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showLuckDialog, setShowLuckDialog] = useState(false);
  const [showFreeWhiteDialog, setShowFreeWhiteDialog] = useState(false)
  const [title, setTitle] = useState('')
  const [remarkList, setRemarkList] = useState([])
  const [hyperMint, setHyperMintList] = useState([])
  const [tipsReamrk, setTipsReamrk] = useState('');
  const [tipsBtns, setTipsBtns] = useState('');
  const [cardList, setCardList] = useState(dataList)
  const account = useAccount();
  const connectKit = useConnectModal();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { chainId, itemInfo, token, issuanceObj, urls } = useSelector(state => {
    return {
      chainId: state.globalModule.chain,
      itemInfo: state.globalModule.itemInfo,
      token: state.loginModule.token,
      issuanceObj: state.globalModule.issuance,
      urls: state.globalModule.urls
    }
  })
  const marks = {
    1: '1',
    33: '5',
    66: '10',
    99: '30'
  }
  const orderInfo = useSelector(state => {
    return state.orderModule.orderInfo
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      if (cardActive < 5) {
        setCardActive(cardActive + 1)
      } else if (cardActive > 4) {
        setCardActive(0)
      }
    }, 300);
    return () => {
      clearTimeout(timer)
    }
  }, [cardActive])

  

  useEffect(() => {
    setHyperMint()
  }, [props.hyperMint])

  useEffect(() => {
    dispatch(issuance_Func())
    dispatch(getWhiteList_Func())
    dispatch(getHyperMint_Func());
  }, [itemInfo?.phase, token])

  useEffect(() =>{
    if (issuanceObj?.id) {
      dispatch(getInfo_func(issuanceObj.id, (list) => {
        let array = [...cardList]
        if (list.length === 5) {
          array[5] = {
            ...array[5],
            img: getImage(urls, list[4].image),
            priceInGame: Number(list[4].recyclingPrice) > 0 ? list[4].recyclingPrice : list[4].priceInGame,
            recyclingPrice: Number(list[4].recyclingPrice) > 0 ? 'Floor Price' : 'Gaming Value',
            unit: Number(list[4].recyclingPrice) > 0 ? 'BNB' : 'USDT',
            cardImg:getBgImage('pc', list[4].level,5),
            logo: Number(list[4].recyclingPrice) > 0 ? floorLogo : gamingLogo
          }

          array[0] = {
            ...array[0],
            img: getImage(urls, list[3].image),
            priceInGame: Number(list[3].recyclingPrice) > 0 ? list[3].recyclingPrice : list[3].priceInGame,
            recyclingPrice: Number(list[3].recyclingPrice) > 0 ? 'Floor Price' : 'Gaming Value',
            unit: Number(list[3].recyclingPrice) > 0 ? 'BNB' : 'USDT',
            cardImg:getBgImage('pc', list[3].level,5),
            logo: Number(list[3].recyclingPrice) > 0 ? floorLogo : gamingLogo
          }
          array[4] = {
            ...array[4],
            img: getImage(urls, list[2].image),
            priceInGame: Number(list[2].recyclingPrice) > 0 ? list[2].recyclingPrice : list[2].priceInGame,
            recyclingPrice: Number(list[2].recyclingPrice) > 0 ? 'Floor Price' : 'Gaming Value',
            unit: Number(list[2].recyclingPrice) > 0 ? 'BNB' : 'USDT',
            cardImg:getBgImage('pc', list[2].level,5),
            logo: Number(list[2].recyclingPrice) > 0 ? floorLogo : gamingLogo
          }

          array[1] = {
            ...array[1],
            img: getImage(urls, list[1].image),
            priceInGame: Number(list[1].recyclingPrice) > 0 ? list[1].recyclingPrice : list[1].priceInGame,
            recyclingPrice: Number(list[1].recyclingPrice) > 0 ? 'Floor Price' : 'Gaming Value',
            unit: Number(list[1].recyclingPrice) > 0 ? 'BNB' : 'USDT',
            cardImg:getBgImage('pc', list[1].level,5),
            logo: Number(list[1].recyclingPrice) > 0 ? floorLogo : gamingLogo
          }
          array[3] = {
            ...array[3],
            img: getImage(urls, list[0].image),
            priceInGame: Number(list[0].recyclingPrice) > 0 ? list[0].recyclingPrice : list[0].priceInGame,
            recyclingPrice: Number(list[0].recyclingPrice) > 0 ? 'Floor Price' : 'Gaming Value',
            unit: Number(list[0].recyclingPrice) > 0 ? 'BNB' : 'USDT',
            cardImg:getBgImage('pc', list[0].level,5),
            logo: Number(list[0].recyclingPrice) > 0 ? floorLogo : gamingLogo
          }
          setCardList(array)
        }
      }))
    }
  }, [issuanceObj?.id, urls])

  function setHyperMint() {
    const arr = []
    const hyperMintList = [];
    const mintList = props.hyperMint
    mintList.forEach((item, index) => {
      if (index < 10) {
        hyperMintList.push(item)
      }
    })
    for (let k = 0; k < hyperMintList.length; k++) {
      const obj = {}
      obj.priceInGame = hyperMintList[k].priceInGame
      obj.recyclingPrice = hyperMintList[k].recyclingPrice
      let image = hyperMintList[k].images
      const newImage = image.split(",")
      obj.images1 = newImage[0] ? newImage[0] : ''
      obj.images2 = newImage[1] ? newImage[1] : ''
      obj.images3 = newImage[2] ? newImage[2] : ''
      obj.newBaby = hyperMintList[k].wallet.substring(0, 7) + '...' + hyperMintList[k].wallet.substring(hyperMintList[k].wallet.length - 7, hyperMintList[k].wallet.length)
      arr.push(obj)
    }
    setHyperMintList(arr)
  }

  // 点击按钮刷新库存数据
  function refreshData() {
    setNum(num + 180)
    const c = document.getElementsByClassName('refresh')
    c[0].style.transform = 'rotateZ(' + num + 'deg)'
    c[0].style.transition = 'all 0.6s'
    props.refreshIssuance()
  }
  // 点击抽奖按钮
  function mintStart() {
    if (account) {
      dispatch(create_order_func(quantity, (data, status) => {
        if (status) {
          setFingerStat(false)
          setMintStat(true)
          setTimeout(() => {
            setMintStat(false)
          }, 400)
          // 先判断是否是白名单补偿
          if (data.order.usedWhitelist) {
            if (data.order.actualPrice === '0') {
              setTitle('Free')
              const remark = [
                'No charge for MINT',
                `<span>Scope of application: ${itemInfo.type}</span>`,
                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
              ]
              setRemarkList(remark)
            } else if (Number(data.order.actualPrice) > 0 && data.order.guaranteed === true) {
              setTitle('Breakeven')
              const remark = [
                `If the repurchase amount is less than the payment amount, the official will compensate for the loss`,
                `<span>Scope of application: ${itemInfo.type}</span>`,
                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
              ]
              setRemarkList(remark)
            } else if (Number(data.order.actualPrice) > 0 && data.order.guaranteed === false) {
              setTitle('Discount')
              const remark = [
                `Use discount price to participate in MINT`,
                `<span>Scope of application: ${itemInfo.type}</span>`,
                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
              ]
              setRemarkList(remark)
            }
            setShowFreeWhiteDialog(true)
          } else {
            // 不是直接调用支付（此时用户为普通用户）
            dispatch(setLoadType('1'))
            dispatch(setLoadStat(true))
            window.web3.eth.getBalance(account).then(value => {
              const balance = window.web3.utils.fromWei(value, "ether");
              if (Number(data.order.totalPrice) > Number(balance)) {
                dispatch(setLoadType(''))
                dispatch(setLoadStat(false))
                setShowBalanceDialog(true)
              } else {
                dispatch(payDone_func((status) => {
                  dispatch(setLoadType(''))
                  dispatch(setLoadStat(false))
                  if (status == 0) {
                    dispatch(setLoadType('3'))
                    dispatch(setLoadStat(true))
                  }
                  if (status == 1) {
                    openResultDialog(true)
                    dispatch(issuance_Func())
                  } else if (status == 2) {
                    //failed
                    setShowLuckDialog(true)
                  } else if (status == 3) {
                    //payFailed
                    // setShowGameDialog(true)
                  } else if (status == 4) {
                    // paidFailed
                  } else if (status === 5) {
                    // 无效订单
                    openTipsDialog()
                    setTipsReamrk('This payment is abnormal,unable to mint.')
                    setTipsBtns('')
                  }
                }, () => {
                  dispatch(setLoadType(''))
                  dispatch(setLoadStat(false))
                }));
              }
            })
          }
          console.log(data.order);
        } else {
          console.log(data)
          if (data === 'SI060') {
            // 尚未发售订单
            openTipsDialog()
            setTipsReamrk('The time for lucky mint has not yet arrived,\n please wait patiently.')
            setTipsBtns('Confirm')
          }
          if (data === 'SI052') {
            openTipsDialog()
            setTipsReamrk(`There is an order has not been completed,\n please complete the payment first.`)
            setTipsBtns('view')
          }
          if (data === 'SI051') {
            openTipsDialog()
            setTipsReamrk(`Insufficient inventory,\n please reduce the number of lucky mint.`)
            setTipsBtns('Confirm')
          }
        }
      }))
    } else {
      connectKit.openConnectModal()
    }
  }
  // 切换抽奖次数
  function getQuantity(value) {
    if (value === 1) {
      setQuantity(1)
    } else if (value === 33) {
      setQuantity(5)
    } else if (value === 66) {
      setQuantity(10)
    } else if (value === 99) {
      setQuantity(30)
    }
  }
  // 打开rules弹框
  function openRulesDialog() {
    setShowRulesDialog(true)
  }
  // 关闭rules弹窗
  function closeRulesDialog() {
    setShowRulesDialog(false)
  }
  // 打开game弹框
  function openGameDialog() {
    setShowGameDialog(true)
  }
  // 关闭game弹框
  function closeGameDialog() {
    setShowGameDialog(false)
  }
  // 关闭余额不足弹窗
  function closeBalanceDialog(type) {
    if (type === 1) {
      setShowBalanceDialog(false)
      setShowAddDialog(true)
    } else {
      setShowBalanceDialog(false)
      dispatch(cancel_order_func(ReasonEnum.cancel, () => {
        // setShowBuyBackDialog(false);
        setShowFreeWhiteDialog(false)
      }))
    }
  }
  // 设置关闭充值弹框
  function closeAddDialog() {
    setShowAddDialog(false)
  }
  // 打开抽奖结果列表
  function openLuckDialog() {
    setShowLuckDialog(true)
  }
  function closeLuckDialog() {
    setShowLuckDialog(false)
  }

  function closeFreeWhiteDialog(type) {
    if (type === 0) {
      dispatch(cancel_order_func(ReasonEnum.cancel, () => {
        // setShowBuyBackDialog(false);
        setShowFreeWhiteDialog(false)
      }))
    } else {
      // setShowBuyBackDialog(false)
      setShowFreeWhiteDialog(false)
      window.web3.eth.getBalance(account).then(value => {
        const balance = window.web3.utils.fromWei(value, "ether");
        if (Number(orderInfo.totalPrice) > Number(balance)) {
          setShowBalanceDialog(true)
        } else {
          dispatch(setLoadType('1'))
          dispatch(setLoadStat(true))
          dispatch(payDone_func((status) => {
            dispatch(setLoadType(''))
            dispatch(setLoadStat(false))
            if (status == 0) {
              dispatch(setLoadType('3'))
              dispatch(setLoadStat(true))
            }
            if (status == 1) {
              openResultDialog(true)
              dispatch(getWhiteList_Func())
              dispatch(issuance_Func())
            } else if (status == 2) {
              //failed
              setShowLuckDialog(true)
            } else if (status == 3) {
              //payFailed
              // setShowGameDialog(true)
            } else if (status == 4) {
              // paidFailed
            } else if (status === 5) {
              // 无效订单
              openTipsDialog()
              setTipsReamrk('This payment is abnormal,unable to mint.')
              setTipsBtns('')
            }
          }, () => {
            dispatch(setLoadType(''))
            dispatch(setLoadStat(false))
          }));
        }
      });
    }
  }

  function openResultDialog() {
    setShowResultDialog(true)
  }
  // 关闭结果页面弹窗
  function closeResultDialog(type) {
    if (type === 1) {
      setShowResultDialog(false)
      openCollectionDialog()
    } else {
      setShowResultDialog(false)
    }
  }
  // 打开收集弹窗
  function openCollectionDialog() {
    setShowCollectionDialog(true)
  }
  // 打开tips提示框
  function openTipsDialog() {
    setShowTipsDialog(true)
  }
  // 关闭tips提示框
  function closeTipsDialog(type) {
    if (type === 1) {
      setShowTipsDialog(false)
      setShowLuckDialog(true)
    } else {
      setShowTipsDialog(false)
    }
  }
  // 关闭收集弹框
  function closeCollectionDialog() {
    setShowCollectionDialog(false)
  }

  return (
    <div className='cardContentNew'>
      <div className="gold1"></div>
      <div className="gold2"></div>
      <div className="gold3"></div>
      <div className="scollTop">
     
     {
       hyperMint.length !==0 &&
       <Swiper
         className="swiper-container swiper-no-swiping"
         spaceBetween={0}
         slidesPerView={1}
         autoplay={{
           delay: 1000, // 默认延迟3s播放下一张
           stopOnLastSlide: false, // 播放到最后一张时停止：否
           disableOnInteraction: false // 用户操作轮播图后停止 ：是
         }}
         direction='vertical'
         loop
       >
         {
           hyperMint.map((item, index) => 
             <SwiperSlide className="swiper-slide" key={index}>
               <div className="baby">
                 <div className="beele">{item.newBaby}</div>
               </div>
               <span className="successful">Won<span className="sol">{' ' + item.recyclingPrice + ' '}</span> BNB of Lucky Mint</span>
               <div className="prize">
                 {
                   item.images1&&<img src={item.images1} alt="" />
                 }
                 {
                   item.images2&&<img src={item.images2} alt="" />
                 }
                 {
                   item.images3&&<img src={item.images3} alt="" />
                 }
                 {
                   item.images3&&<div className="ellipsis">...</div>
                 }
               </div>
             </SwiperSlide>
           )
         }
       </Swiper>
     }
   </div> 
      <DengDiv />


      <div className='drawContent'>
          <div className="cards">
            {
              cardList.map((item, index) =>
                <li className={cardActive === index ? 'cardActive' : 'cardItem'} key={index}>
                  {
                    index === 0 ?
                      <div className="cardImgs">
                        <img src={item.cardImg} alt="" />
                      </div> :
                      <img className="cardImg" src={item.cardImg} alt="" />
                  }
                  <img src={item.img} className="hippoCard" alt="" />
                  <div className="number">
                    {item.priceInGame}
                    <span className='sol'>{item.unit}</span>
                  </div>
                  <div className="value">
                    {
                      index === 0 ?
                        <img src={item.logo} className="meFive" alt="" /> :
                        <img src={item.logo} className="me" alt="" />
                    }
                    {item.recyclingPrice}
                  </div>
                </li>
              )
            }
          </div>
          <div className="drawNumber">
              <div className="total">
                {
                  props.issuanceObj?
                  (props.issuanceObj.total - props.issuanceObj.sold)+'/'+props.issuanceObj.total:'0/0'
                }
                <RedoOutlined onClick={refreshData} className="refresh" />
              </div>
            {/* <i></i><div className="middle">The Hyper Hippo（HHJC）will be drawn.</div> */}
          </div>
      </div>
      <div className="slider">
        <div className="blcok">
          <Slider marks={marks} step={null} tooltip={{ formatter: null }} defaultValue={33} onChange={getQuantity} />
        </div>
      </div>

      <div className='luckyMintDiv'>
        <img className="mint" src={luckyMintBtn}  onClick={mintStart} />
        <div><label>{
            props.issuanceObj ?
              props.issuanceObj.price : '0.3'
          }</label> BNB/MINT</div>
      </div>
      <div className="bntsDiv">
        <img className='pcHistory' src={pcHistory} onClick={openLuckDialog}/>
        <img className='pcRules' src={pcRules}  onClick={openRulesDialog} />
      </div>
      {/* <div className="mergeAndGame">
        <div className="merge"><div className="mergeContai" onClick={openRulesDialog}></div></div>
        <div className="game"><div className="gameContai" onClick={openGameDialog}></div></div>
      </div>  */}
      <RulesDialog showRulesDialog={showRulesDialog} closeRulesDialog={closeRulesDialog} />
      {/* <RuleDescription showRulesDialog={showRulesDialog} closeRulesDialog={closeRulesDialog} /> */}
      <GameDialog showGameDialog={showGameDialog} closeGameDialog={closeGameDialog} />
      {/* <BuyBackDialog showBuyBackDialog={showBuyBackDialog} closeBuyBackDialog={closeBuyBackDialog} buyStat={buyStat} /> */}
      <BalanceDialog showBalanceDialog={showBalanceDialog} closeBalanceDialog={closeBalanceDialog} />
      <AddFunddsDialog showAddDialog={showAddDialog} closeAddDialog={closeAddDialog} />
      <LuckListDialog showLuckDialog={showLuckDialog} closeLuckDialog={closeLuckDialog} />
      {showResultDialog && <ResultDialog showResultDialog={showResultDialog} closeResultDialog={closeResultDialog} quantity={quantity} orderInfo={orderInfo} />}
      <CollectionDialog showCollectionDialog={showCollectionDialog} closeCollectionDialog={closeCollectionDialog} />
      <TipsDialog showTipsDialog={showTipsDialog} closeTipsDialog={closeTipsDialog} tipsReamrk={tipsReamrk} tipsBtns={tipsBtns} />
      <FreeWhiteDialog showFreeWhiteDialog={showFreeWhiteDialog} closeFreeWhiteDialog={closeFreeWhiteDialog} title={title} remarkList={remarkList} />
    </div>
  )
}

export default CardContent