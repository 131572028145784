import './index.scss'
import { Modal } from 'antd'
import footBallImg from '@/assets/images/home/football.png'
import boxImg from '@/assets/images/home/boxImg.png'

type Props={
  showBnbDialog:boolean,
  closeBnbDialog?:Function,
  onViewClick?:Function
  nft:any
}
function BnbDialog(props:Props) {
  function handleCancel() {
    props.closeBnbDialog && props.closeBnbDialog()
  }
  return (
    <Modal className='luck' footer={null} title={null} open={props.showBnbDialog} keyboard={false} maskClosable={false} wrapClassName='bnbDialog' onCancel={handleCancel}>
      <div className='bnbContent'>
        <img src={footBallImg} className='topImg' alt="" />
        <div className='title'>Congratulations!</div>
        <div className='nfc'>
          <img src={`https://www.soga.io/metadata/spec-nfts/hcv/images/${props.nft?.tokenId}.png`} className="nfcImg" alt="" />
        </div>
        <div className='bnbTips'>
          You will enjoy a number of privileges on the SOGA platform, <br/> click for detailst.
        </div>
        <div className='view' onClick={()=>{
          props.onViewClick && props.onViewClick();
        }}>
          View
        </div>
      </div>
    </Modal>
  )
}

export default BnbDialog