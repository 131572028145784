import './index.scss'
import { Modal } from 'antd';
import shopImg from '../../../assets/images/home/shopping.png'

function HistoryDialog(props) {
  function handleCancel() {
    props.closeHistroyDialog()
  }
  const historyList = [
    { imgLogo:require('../../../assets/images/home/hippo@2x.png'), name: 'Hippo', num: '#3321', price: '0.3', doller: '23.33', from: 'WEDR...', to: 'UYIL...', time: '2022-11-11 22:33:00' },
    { imgLogo:require('../../../assets/images/home/hippo@2x.png'), name: 'Hippo', num: '#3321', price: '0.3', doller: '23.33', from: 'WEDR...', to: 'UYIL...', time: '2022-11-11 22:33:00' },
    { imgLogo:require('../../../assets/images/home/hippo@2x.png'), name: 'Hippo', num: '#3321', price: '0.3', doller: '23.33', from: 'WEDR...', to: 'UYIL...', time: '2022-11-11 22:33:00' },
    { imgLogo:require('../../../assets/images/home/hippo@2x.png'), name: 'Hippo', num: '#3321', price: '0.3', doller: '23.33', from: 'WEDR...', to: 'UYIL...', time: '2022-11-11 22:33:00' },
    { imgLogo:require('../../../assets/images/home/hippo@2x.png'), name: 'Hippo', num: '#3321', price: '0.3', doller: '23.33', from: 'WEDR...', to: 'UYIL...', time: '2022-11-11 22:33:00' },
    { imgLogo:require('../../../assets/images/home/hippo@2x.png'), name: 'Hippo', num: '#3321', price: '0.3', doller: '23.33', from: 'WEDR...', to: 'UYIL...', time: '2022-11-11 22:33:00' },
    { imgLogo:require('../../../assets/images/home/hippo@2x.png'), name: 'Hippo', num: '#3321', price: '0.3', doller: '23.33', from: 'WEDR...', to: 'UYIL...', time: '2022-11-11 22:33:00' },
    { imgLogo:require('../../../assets/images/home/hippo@2x.png'), name: 'Hippo', num: '#3321', price: '0.3', doller: '23.33', from: 'WEDR...', to: 'UYIL...', time: '2022-11-11 22:33:00' },
    { imgLogo:require('../../../assets/images/home/hippo@2x.png'), name: 'Hippo', num: '#3321', price: '0.3', doller: '23.33', from: 'WEDR...', to: 'UYIL...', time: '2022-11-11 22:33:00' },
    { imgLogo:require('../../../assets/images/home/hippo@2x.png'), name: 'Hippo', num: '#3321', price: '0.3', doller: '23.33', from: 'WEDR...', to: 'UYIL...', time: '2022-11-11 22:33:00' },
  ]
  return(
    <Modal footer={null} title={null} open={props.showHistoryDialog} keyboard={false} maskClosable={false} wrapClassName='historyDialog' onCancel={handleCancel}>
      <div className="dialogContent">
        <div className="headerBg"></div>
        <div className="dialogHeader">HIstory</div>
        <div className='tableHeader'>
          <div className='item'>Item</div>
          <div className='priceItem'>Price</div>
          <div className='fromItem'>From</div>
          <div>To</div>
          <div className='timeItem'>Time</div>
        </div>
        <div className='tableContent'>
          {
            historyList.map((item, index) =>
              <div key={index} className="tableItem">
                <img src={shopImg} className="shopping" alt="" />
                <img src={item.imgLogo} className="imgPic" alt="" />
                <div className='name'>
                  <div className='hisName'>{item.name}</div>
                  <div>{item.num}</div>
                </div>
                <div className='price'>
                  <div className='bnb'>{item.price}BNB</div>
                  <div>${item.doller}</div>
                </div>
                <div className='from'>{item.from}</div>
                <div className='to'>{item.to}</div>
                <div className='time'>{item.time}</div>
              </div>
            )
          }
        </div>
      </div>
    </Modal>
  )
}

export default HistoryDialog