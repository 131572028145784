import { combineReducers } from 'redux';
// import { History,createBrowserHistory } from 'history';  
// import {connectRouter} from 'connected-react-router';  
import globalReducer from './globalClice';
import loginReducer from './loginClice';
import orderReducer from './orderClice';
import activitiesReducer from './activitiesClice'

export default function createRootReducer() {
  return combineReducers({
    globalModule: globalReducer,
    loginModule: loginReducer,
    orderModule: orderReducer,
    activitiesModule: activitiesReducer
  });
}