import { getListOnSale_func, setItemInfo, setActiveStat, setTradIndexStat, issuance_Func } from '@features/globalClice'
import { useDispatch, useSelector } from 'react-redux'
import './index.scss'
import bgImg from '@/assets/images/home/hippo-bg.png'
import hippoLogo from '@/assets/images/home/gameta_logo@2x.png'
import endImg from '@/assets/images/home/end.png'
import { useNavigate } from "react-router-dom";
import Sponsor from '../../../components/pc/sponsor/index'
import { Pagination } from 'antd';
import { useState, useEffect } from "react";
import mint1Img from '@/assets/images/home/1of2.png'
import mint5Img from '@/assets/images/home/1of5.png';
import { hippoToPageMap } from '@/config'
import { RootState } from '@/store'
import leftImg from '@/assets/images/home/left.png'
import rightImg from '@/assets/images/home/right.png'

function HippoList() {
  const navigate = useNavigate()
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [editionList, setEditionList] = useState<Array<any>>([]);
  const [newArr, setNewArr] = useState<Array<any>>([])
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(setActiveStat(activeStat))

    dispatch(setTradIndexStat(false))
    dispatch(getListOnSale_func((data: any) => {
      // console.log(data,"======datadata")
      setEditionList(data || [])
    }))
  }, [])
  function itemRender(current: number, type: string, originalElement: any) {
    if (type === 'prev') {
      return <div className='nextBtn'>Last page</div>;
    }
    if (type === 'next') {
      return <div className='nextBtn'>Next page</div>;
    }
    return originalElement;
  }
  useEffect(() => {
    onChange(1, 8)
    setTotal(editionList.length)
  }, [editionList])
  // 河马设置手动分页器
  function onChange(page: number, pageSize: number) {
    setCurrent(page)
    let start = pageSize * page - 8
    let end = pageSize * page
    if (editionList.length > 0) {
      let newData: any[] = editionList
      if (newData.length <= pageSize) {  //这种情况为只有20条或者20条以下数据的情况
        setNewArr([...newData])
      } else {  //这种情况为大于20条数据
        if (pageSize * page >= newData.length) {//用户点击的那一页是最后一页的数据，比如有49条数据，用户点击第3页，第二3就应该显示最后9条的数据
          setNewArr([...newData.slice(start)])
        } else { //用户点击的那一页不是最后一页的数据，比如有49条数据，用户点击第二页，第二页就应该显示20至40条的数据
          setNewArr([...newData.slice(start, end)])
        }
      }
    }
  }
  function enter(item: any) {
    if (item.status === 2 || item.status === 4) {
      dispatch(setItemInfo(item))
      sessionStorage.setItem('issuanceId', item.id)
      sessionStorage.setItem('issuanceType', item.type)

      dispatch(issuance_Func())
      const obj = hippoToPageMap.find(e => {
        return e.phase == item.phase
      })
      if (obj) {
        navigate(obj.pageUrl, {
          state: {
            issuanceId: item.id
          }
        })
      } else {
        if (item.type === '1of2') {
          // navigate('/hippoclub', {
          //   state: {
          //     issuanceId: item.id
          //   }
          // })
          navigate('/hippomintmini', {
            state: {
              issuanceId: item.id
            }
          })
        } else {

          navigate('/hippomint', {
            state: {
              issuanceId: item.id
            }
          })
        }

      }
    }
  }

  const { urls }: { urls: any } = useSelector((state: RootState) => {
    return {
      urls: state.globalModule.urls
    }
  })

  const getLogo = (item: any) => {
    if (urls) { 
      const logoList = item.images.split(',');
      const logo = logoList[logoList.length - 1];
      if (logo.includes('nfts')) {
        return `${urls}/${logo}`
      } else {
        return `${urls}/${logo}/preview.png`
      }
    } else {
      return hippoLogo
    }

  }

  return (
    <div className='hippoList'>
      <div className='hippoInner'>
        <div className='header'>
          <img src={leftImg} className='partnerLeft' alt="" />
          <div className='headerInner'>LUCKY MINT</div>
          <img src={rightImg} className='partnerRight' alt="" />
        </div>
        {/* {
          newArr.length  === 0?
          <div className='no'>No data</div>:
          <>
            <div className='content'>
              {
                newArr.map((item:any, index) =>
                  <div className='edition' key={index} onClick={() => { enter(item.status) }}>
                    <img src={bgImg} className='hippoImg' alt="" />
                    <div className='hippoBottom'>
                      <div className='logo'><img src={hippoLogo} alt="" /></div>
                      <div className='bala'>{item.title}</div>
                      <div className='detail'>
                        {item.sold} / {item.total}&nbsp;
                        <span>|</span>
                        &nbsp;{item.price} &nbsp;{item.currency}/MINT
                      </div>
                      <div className='of'>{item.studio}</div>
                      {
                        item.status === 4 &&
                        <img src={endImg} className='end' alt="" />
                      }
                    </div>
                  </div>
                )}
            </div>
            <Pagination
              className='pagination'
              defaultCurrent={1}
              defaultPageSize={8}
              current={current}
              total={total}
              itemRender={itemRender}
              onChange={onChange}
              showSizeChanger={false}
          />
          </>
        } */}
        <div className='content'>
          {
            newArr.map((item: any, index) =>
              <div className='edition' key={index} onClick={() => { enter(item) }}>
                {
                  item.type === '1of2' ?
                    <img src={mint1Img} className='hippoImg' alt="" /> :
                    <img src={mint5Img} className='hippoImg' alt="" />
                }
                <div className='hippoBottom'>
                  <div className='logo'><img src={getLogo(item)} alt="" /></div>
                  <div className='bala'>{item.title}</div>
                  <div className='detail'>
                    {item.total - item.sold} / {item.total}&nbsp;
                    <span>|</span>
                    &nbsp;{item.price} &nbsp;{item.currency}/MINT
                  </div>
                  {/* <div className='of'>{item.type}</div> */}
                  {
                    item.status === 4 &&
                    <img src={endImg} className='end' alt="" />
                  }
                </div>
              </div>
            )}
        </div>
        <Pagination
          className='pagination'
          defaultCurrent={1}
          defaultPageSize={8}
          current={current}
          total={total}
          itemRender={itemRender}
          onChange={onChange}
          showSizeChanger={false}
        />
        <Sponsor />
      </div>

    </div>
  )
}

export default HippoList