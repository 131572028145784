import './index.scss'
import { Modal } from 'antd';
import ghostImg from '../../../assets/images/home/ghost.png'
import frogprinceImg from '../../../assets/images/home/frogprince.png'
import investMasterImg from '../../../assets/images/home/InvestMaster.png'
import antsrunnerImg from '../../../assets/images/home/antsrunner.png'
import humanToweImg from '../../../assets/images/home/HumanTowe.png'
import sleepragdollImg from '../../../assets/images/home/sleepragdoll.png'
import numberImg from '../../../assets/images/home/Number.png'
import tadpoleCountImg from '../../../assets/images/home/TadpoleCount.png'
import superimg from '../../../assets/images/home/super.png'
import cloneImg from '../../../assets/images/home/Clone.png'
import gametaImg from '../../../assets/images/home/gameta-logo.png'
import log2Img from '../../../assets/images/home/log2.png'
import discordImg from '../../../assets/images/home/icon-Discord.png'

function GameDialog(props) {
  function handleCancel() {
    props.closeGameDialog()
  }
  function ghost() {
    window.open('https://play.google.com/store/apps/details?id=com.fungames.ghostrun')
  }
  function forg() {
    window.open('https://play.google.com/store/apps/details?id=com.fungames.frogprince')
  }
  function master() {
    window.open('https://play.google.com/store/apps/details?id=com.fungames.investmaster')
  }
  function antsrunner() {
    window.open('https://play.google.com/store/apps/details?id=com.walkman.antsrunner')
  }
  function humantower() {
    window.open('https://play.google.com/store/apps/details?id=com.fungames.humantower')
  }
  function sleepragdoll() {
    window.open('https://play.google.com/store/apps/details?id=com.fungames.sleepragdoll')
  }
  function count() {
    window.open('https://play.google.com/store/apps/details?id=com.fungames.Numbercount')
  }
  function tadpolecount() {
    window.open('https://play.google.com/store/apps/details?id=com.fungames.tadpolecount')
  }
  function supergloves() {
    window.open('https://play.google.com/store/apps/details?id=com.fungames.supergloves')
  }
  function clonerush() {
    window.open('https://play.google.com/store/apps/details?id=com.gameta.clonerush')
  }
  function goMe() {
    window.open('https://www.gameta.pro/#/')
  }
  function goTwitter() {
    window.open('https://twitter.com/GametaHippo')
  }
  function goDiscord() {
    window.open('https://discord.com/invite/yqa229h7tZ')
  }
  return(
    <Modal className='game' footer={null} title={null} open={props.showGameDialog} keyboard={false} wrapClassName='gameDialog' maskClosable={false} onCancel={handleCancel}>
      <div className="gameContent">
        <div className="headerBg"></div>
        <div className="dialogHeader">game to earn</div>
        <div className="content">
          <p>As the world's #1 Web3 gaming platform (by user count), Gameta provides infrastructure and distribution services for ultra-casual games, attracting mass amount of mainstream players to easily transition to Web3. We achieve this through providing a matrix of ultra-casual games, and building a meta-universe that truly belongs to the users.</p>
          <div>Note:</div>
          <div>1) The value of all Gameta NFT means the revenue you can expect to earn by playing Gameta games (and complete game related tasks) in a fixed period of time;</div>
          <div>2) Users need to consistently complete games and tasks according to the official rules in order to maximize earnings;</div>
          <div>3) The final earnings that can be earned depend on the users’ participation frequency, participation period, game skill level, and can be strongly influenced by market fluctuations.</div>
          <div className="four">4) Games supporting GMB and GHC are expected to be officially launched in early 2023.</div>
          <div>You can click below to try out the current Gameta games. Please note that these games <span>do not support</span> the use of GMB and GHC NFT at this time.</div>
        </div>
        <div className="games">
          <div className="one"><img src={ghostImg} onClick={ghost} alt="" /></div>
          <div className="one" ><img src={frogprinceImg} onClick={forg} alt="" /></div>
          <div className="one"><img src={investMasterImg} onClick={master} alt="" /></div>
          <div className="one"><img src={antsrunnerImg} onClick={antsrunner} alt="" /></div>
          <div className="one"><img src={humanToweImg} onClick={humantower} alt="" /></div>
          <div className="one"><img src={sleepragdollImg} onClick={sleepragdoll} alt="" /></div>
          <div className="one"><img src={numberImg} onClick={count} alt="" /></div>
          <div className="one"><img src={tadpoleCountImg} onClick={tadpolecount} alt="" /></div>
          <div className="one"><img src={superimg} onClick={supergloves} alt="" /></div>
          <div className="one"><img src={cloneImg} onClick={clonerush} alt="" /></div>
        </div>
        <div className="footer">
          <div className="imgDiv">
            <div><img className="img1" src={gametaImg} onClick={goMe} alt="" /></div>
            <div><img className="img3" src={log2Img} onClick={goTwitter} alt="" /></div>
            <div><img className="img4" src={discordImg} onClick={goDiscord} alt="" /></div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default GameDialog