import './index.scss'
import play1Img from '@/assets/images/home/player1.png'
import play2Img from '@/assets/images/home/player2.png'
import boxImg from '@/assets/images/home/boxImg.png'
import Footer from '@/components/pc/pcFooter'
import qiuImg1 from '@/assets/images/home/quan1.png'
import qiuImg2 from '@/assets/images/home/quan2.png'
import kefuImg from '@/assets/images/home/kefu.png'
import { useEffect, useState } from 'react'
import BnbDialog from '../bnbDialog'
import { useDispatch, useSelector } from 'react-redux';
import { ActivitiesType, getActivities_func, boxReceiveNFT_func, getNFTQueryStatus_func } from '@features/activitiesClice';
import { useAccount, useConnectModal } from '@particle-network/connect-react-ui'
import { RootState } from '@/store';
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import loadingImg from '@/assets/images/home/load_white.gif'


function BnbActivity() {
  const account = useAccount()
  const connectKit: any = useConnectModal();
  const dispatch = useDispatch();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [whiteLoading, setLoading] = useState(false)
  const [nft, setNft] = useState();
  const navigate = useNavigate();
  const { chain, activitiesInfo, boxContractBalance, contractBalance, boxContractAddressList, token }:
    {
      chain: any, activitiesInfo: any, boxContractBalance: Number,
      contractBalance: Number, boxContractAddressList: any[], token: any
    } = useSelector((state: RootState) => {
      return {
        chain: state.globalModule.chain,
        activitiesInfo: state.activitiesModule.activitiesInfo,
        boxContractBalance: state.activitiesModule.boxContractBalance,
        boxContractAddressList: state.activitiesModule.boxContractAddressList,
        contractBalance: state.activitiesModule.contractBalance,
        token: state.loginModule.token
      }
    })

  useEffect(() => {

    if (activitiesInfo && activitiesInfo.status === 1) {
      // if (contractBalance > 0) {    //有 NFT跳转到 抽奖页
      //   // navigate("/activity", {
      //   //   replace: true,
      //   // })
      //   navigate("/hippovip", {
      //     replace: true,
      //   })
      // }
      console.log(boxContractBalance, "========boxContractBalanceboxContractBalance")
      if (boxContractBalance > 0) {
        setBtnDisabled(false)
      } else {
        setBtnDisabled(true)
      }
    }
  }, [activitiesInfo, boxContractBalance, contractBalance])

  useEffect(() => {
    if (account && chain?.id && token) {
      dispatch(getActivities_func(ActivitiesType.bnbActivity, chain?.id, [account]))
    } else if (!account && !token) {
      connectKit.openConnectModal();
    }
  }, [account, chain?.id, token])


  const [reamrk, setRemark] = useState(false)
  const [showBnbDialog, setShowBnbDialog] = useState(false)
  function openBox(type: number) {
    setShowBnbDialog(true)
    // setRemark(type?true: false)
  }
  function closeBnbDialog() {
    setShowBnbDialog(false)
  }
  return (
    <div className='bnbActivity'>
      <div className='bnbInner'>
        <div className='remark'>
          <div>Gameta & BNB CHAIN </div>
          <div>Exclusive World Cup blind box </div>
        </div>
        <div className='box'>
          {
            (boxContractAddressList.length === 0 || contractBalance > 0) ?
              <div>
                <div className='walletRemark'>
                  Sorry, the NFT could not be found in your wallet.<br />
                  Please switch your wallet or contact customer service.
                </div>
                <div className='kf' onClick={() => { window.open('https://discord.com/invite/yqa229h7tZ') }}>
                  <img src={kefuImg} alt="" />
                </div>
              </div> :
              <div className='bnbBox'>
                <div className='boxInner'>

                  <img src={boxContractAddressList[0].image} className="boxImage" alt="" />
                  <div className={`openBtn ${btnDisabled ? 'disabled' : ''}`} onClick={() => {
                    if (!btnDisabled) {
                      setLoading(true)
                      dispatch(boxReceiveNFT_func(boxContractAddressList[0].tokenId, chain?.id, [account], (code: string, contractAddress: any) => {
                        setLoading(false)
                        if (code == "0") {
                          if (contractAddress.length > 0) {
                            setNft(contractAddress[0])
                          }
                          openBox(1)
                        } else if (code == "SI083") {  //已被领取
                          //领取失败
                          message.error("Already received");
                        }
                      }))
                    }
                  }}>
                    Open Now
                  </div>
                </div>
              </div>
          }
        </div>
        <div className='player'>
          <img src={play1Img} className="play1" alt="" />
          <img src={play2Img} className="play2" alt="" />
          <img src={qiuImg1} className="qiu1" alt="" />
          <img src={qiuImg2} className="qiu2" alt="" />
        </div>
      </div>
      {
        whiteLoading === true &&
        <div className='masks'>
          <img src={loadingImg} className='loadWhite' alt="" />
        </div>
      }
      <BnbDialog nft={nft} showBnbDialog={showBnbDialog} closeBnbDialog={closeBnbDialog} onViewClick={() => {

        //-1 无nft  0 初始化，1 minting中 2 mint 完成  3 mint失败
        dispatch(getNFTQueryStatus_func((data: any) => {
          if (data == 2) {
            //跳转到抽奖页
            navigate("/activity", {
              replace: true
            })
            // navigate("/hippovip", {
            //   replace: true
            // })
          } else {
            //跳转到抽奖页
            // navigate("/christmas", {
            //   replace: true
            // })
            navigate("/hippovip", {
              replace: true
            })
          }

        }))

      }} />
      <Footer />
    </div>
  )
}

export default BnbActivity