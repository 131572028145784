import { axios } from '@utils/request'
import Qs from 'qs'



// 1  获取hipp vio nft 活动  2  抽奖活动  3 bnb活动
export enum ActivitiesType {
    getHipp = 1,
    luckDrawActivity = 2,
    bnbActivity = 3
}
export function getActivities(id: ActivitiesType) {
    return axios({
        url: `/activities:act`,
        method: 'get',
        params: {
            id
        }
    })
}

/**
 * 用户mint 次数
 * @returns 
 */
export function getMintCount() {
    return axios({
        url: `/activities:userMintCount`,
        method: 'get'
    })
}
/**
 * 领取happo vip nft
 * @returns 
 */
export function getReceiveNft() {
    return axios({
        url: `/activities:receiveNft`,
        method: 'post'
    })
}
/**
 * 通过nft的token查询当天抽奖次数
 * @param mint 
 * @returns 
 */
export function getAwardLotteryCount(mint: string) {
    return axios({
        url: "/activities:awardLotteryCount",
        method: 'post',
        data: Qs.stringify({
            mint
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

/**
 * 通过nft抽奖
 * @param mint 
 * @returns 
 */
export function postAwardLottery(mint: string) {
    return axios({
        url: "/activities:awardLottery",
        method: 'post',
        data: Qs.stringify({
            mint
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}
/**
 * 查询领奖记录
 * @param mint 
 * @returns 
 */
export function getAwardRecordUser(pageStart: Number = 0, pageSize: Number = 20) {
    return axios({
        url: "/activities:awardRecordUser",
        method: 'get',
        params: {
            pageStart,
            pageSize
        }
    })
}

/**
 * 盲盒领取nft
 * @param mint 
 * @returns 
 */
export function postBoxReceiveNFT(mint: string) {
    return axios({
        url: `/activities:boxReceiveNft`,
        method: 'post',
        data: Qs.stringify({
            mint
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}


export function getNFTQueryStatus(){
    //activities:nftQueryStatus
    return axios({
        url: `/activities:nftQueryStatus`,
        method: 'get'
    })
}
