import "../index/index.scss";
import CardContent from '../../components/pc/cardContentNew/index'
// import LuckMintList from '../../components/pc/luckMintList/index'
import { issuance_Func } from '../../features/globalClice'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '@/store';
import { Spin } from 'antd'
import notOpenImg from '../../assets/images/home/not-open.png'
import log2Img from '../../assets/images/home/log2.png'
import discordImg from '../../assets/images/home/icon-Discord.png'
import Footer from '@/components/pc/pcFooter'
import { Navigate, useLocation, useNavigate } from "react-router-dom";


export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  function getIssuance() {
    dispatch(issuance_Func())
  }

  const { issuanceObj, hyperMint }: { issuanceObj: any, hyperMint: any } = useSelector((state: RootState) => {
    return {
      issuanceObj: state.globalModule.issuance,
      hyperMint: state.globalModule.hyperMintList
    }
  })


  function goDiscord() {
    window.open('https://discord.com/invite/yqa229h7tZ')
  }
  function goTwitter() {
    window.open('https://twitter.com/GametaHippo')
  }

  function back() {
    navigate('/')
  }

  // 当issuanceObj下的status为3时 需要展示此遮罩
  const antIcon = <div className="notContent">
    <div className="notRemark">This issue of Lucky Mint has ended. <br />If you want to continue participating, please join the community.</div>
    <div className="notImgs">
      <div className="notOne"><img className="img4" onClick={goDiscord} src={discordImg} alt="" /></div>
      <div><img className="img3" src={log2Img} onClick={goTwitter} alt="" /></div>
    </div>
    <div className="back" onClick={back}>Back</div>
  </div>


  const state = useLocation().state;


  if (state == undefined) {
    return <Navigate replace to="/" />
  }

  return <>

    {/* {tradIndexStat ?
      <div className="trade">
        <div className="tradeContent">
          <img src={notOpenImg} alt="" />
          <div className="remark">The marketplace is under construction, follow our twitter<br />(<span onClick={() => { window.open('https://twitter.com/GametaHippo') }}>https://twitter.com/GametaHippo</span>)to receive first-hand progress updates.</div>
          {'Coming soon...'}
        </div>
      </div> :
      <div className="hippoContent">
        <div className="hyper">{issuanceObj?.title}</div>
        <div className="studio">{issuanceObj?.studio}</div>
        <CardContent issuanceId={state.issuanceId} issuanceObj={issuanceObj} hyperMint={hyperMint} refreshIssuance={getIssuance} />
        <Spin indicator={antIcon} spinning={issuanceObj?.status === 3} />
        <LuckMintList hyperMint={hyperMint} />
        <div className="footer">
          <Footer />
        </div>
      </div>}</> */}

    <div className="hippoContent">
      <div className="hyper">{issuanceObj?.title}</div>
      <div className="studio">{issuanceObj?.studio}</div>
      <CardContent issuanceId={state.issuanceId} issuanceObj={issuanceObj} hyperMint={hyperMint} refreshIssuance={getIssuance} />
      <Spin indicator={antIcon} spinning={issuanceObj?.status === 4} />
      {/* <LuckMintList hyperMint={hyperMint} /> */}
      <div className="footer">
        <Footer />
      </div>
    </div></>
}