import React, { useEffect, useState } from "react";
import moment from "moment";

type Props = {
    createTime: any
}
export default function Index({ createTime }: Props) {
    const [time, setTime] = useState<number>(0);

    const [timeText, setTimeText] = useState<string>('');

    useEffect(() => {
        //5分钟为300000毫秒
        const stopTime = parseInt(((createTime + 300000) / 1000).toString());
        let date = new Date().getTime()
        const dateTime = parseInt((date / 1000).toString())
        let surpluTime = stopTime - dateTime;

        console.log(surpluTime)
        setTime(surpluTime >= 0 ? surpluTime : 0)
        formatDuring(surpluTime >= 0 ? surpluTime : 0)
    }, [createTime])

    useEffect(() => {
        let timeId: any;
        if (time > 0) {
            if (time >= 0) {
                timeId = setTimeout(() => {
                    setTime(time - 1);
                    formatDuring(time)
                }, 1000)
            }
        }
        return () => {
            clearTimeout(timeId)
        }
    }, [time]);



    function formatDuring(time: number) {
        // console.log(time, "=====time")
        if (time <= 0) {
            setTimeText('')
        } else {
            var minutes = parseInt(((time % (60 * 60)) / (60)).toString());
            var seconds = parseInt((time % 60).toString()) < 10 ? '0' + parseInt((time % 60).toString()) : parseInt(((time % 60)).toString())
            setTimeText(`${minutes}:${seconds}`)
        }
    }

    return <>{timeText}</>
}
