import { axios } from '../utils/request'

export function isRestrictedCountry() {
  // 检查用户所在的地区是否是受限制的
  return axios({
    url: `/isRestrictedCountry`,
    method: 'get',
  })
}

// export function issuance() {
//   // 获取当前发售
//   return axios({
//     url: `/issuance:current`,
//     method: 'get',
//   })
// }

export function issuance(issuanceId) {
  // 获取当前发售
  return axios({
    url: `/issuance:get`,
    method: 'get',
    params: {
      issuanceId
    }
  })
}


export function getHyperMint(params) {

  return axios({
    url: `/ranks:hyperMint`,
    method: 'get',
    params: params
  })
}

/**
 * 获取在售列表
 * @returns 
 */
export function getListOnSale() {
  return axios({
    url: "/issuance:listOnSale",
    method: 'get'
  })
}
/**
 * 获取非在售列表
 * @param {*} start 
 * @param {*} size 
 * @returns 
 */
export function getListNotForSale(start = 0, size = 1000) {
  return axios({
    url: "/issuance:listNotForSale",
    method: 'get',
    params: {
      start,
      size
    }
  })
}

export function getUrls() {
  // 获取url地址
  return axios({
    url: `/parameter:urls`,
    method: 'get'
  })
}

export function getPrice() {
  // 获取货币
  return axios({
    url: `/coins:price`,
    method: 'get'
  })
}

export function getUserOrders(params) {
  // 获取用户订单列表
  return axios({
    url: `/orders:userOrders`,
    method: 'get',
    params: params
  })
}

/**
 * 获取库存数量
 * @param {*} issuanceId 
 * @returns 
 */
export function quantityInStock(issuanceId) {
  // 获取当前发售
  return axios({
    url: `/issuance:quantityInStock`,
    method: 'get',
    params: {
      issuanceId
    }
  })
}

/**
 * 获取发售的项目
 * @param {*} issuanceId 
 * @returns 
 */
export function getIssuanceItems(issuanceId) {
  return axios({ 
    url: `/issuance:issuanceItems`,
    method: 'get',
    params: {
      issuanceId
    }
  })
}

/**
 * 获取白名单
 */
 export function getWhiteList() {
  return axios({ 
    url: `/orders:myWhitelist`,
    method: 'get'
  })
}