import { useEffect } from 'react';


function setBaseFontSize() {
  const clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const pcWidth = 1440;
  let scale = null;
  const baseSize = 100;

  if (clientWidth < 900) {

    document.getElementsByTagName('body')[0].classList.add('mobile');
    localStorage.setItem('isMobile', 1);

    scale = (clientWidth / 750).toFixed(3);
  } else if(clientWidth > 1440){
    document.getElementsByTagName('body')[0].classList.remove('mobile');
    localStorage.setItem('isMobile', 0); 
    scale = (pcWidth / 1520).toFixed(3);
  } else {
    document.getElementsByTagName('body')[0].classList.remove('mobile');
    localStorage.setItem('isMobile', 0); 
    scale = (clientWidth / 1520).toFixed(3);
  }

  // 设置页面根节点字体大小
  document.documentElement.style.fontSize =
    baseSize * Math.min(scale, 10) + 'px';
}
setBaseFontSize();

function useSetBaseFontSize() {

  useEffect(() => {

    window.addEventListener('resize', setBaseFontSize);
    return () => {
      window.removeEventListener('resize', setBaseFontSize);
    };
  }, []); 
}

export default useSetBaseFontSize