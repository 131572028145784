const REPORT = {
  list: [
    {id: 1, factoryNo: '001', factoryName: '华为', materialCode: '3Z668A', materialName: '钣金门', bomLevel: 2, version: 'N167', qty: 30},
    {id: 2, factoryNo: '002', factoryName: '谷歌', materialCode: '3Z228A', materialName: '玻璃门', bomLevel: 3, version: 'N167', qty: 150},
    {id: 3, factoryNo: '001', factoryName: '华为', materialCode: 'WS2452', materialName: '钣金门2', bomLevel: 1, version: 'N167', qty: 230},
    {id: 4, factoryNo: '002', factoryName: '谷歌', materialCode: '2Z838A', materialName: '散热器', bomLevel: 2, version: 'N167', qty: 76},
    {id: 5, factoryNo: '003', factoryName: '中芯国际', materialCode: 'QJ8623', materialName: '小J骨架', bomLevel: 2, version: 'N167', qty: 99},
    {id: 6, factoryNo: '004', factoryName: '宁德时代', materialCode: '255FE3', materialName: '小J面板', bomLevel: 1, version: 'N167', qty: 30},
    {id: 7, factoryNo: '004', factoryName: '宁德时代', materialCode: '90JY65', materialName: '支撑臂', bomLevel: 2, version: 'N167', qty: 52},
    {id: 8, factoryNo: '001', factoryName: '华为', materialCode: '74GER4', materialName: '冷凝器', bomLevel: 3, version: 'N167', qty: 16},
  ]
}

export default REPORT
