import './index.scss'
import { useDispatch, useSelector } from 'react-redux'; 
import loadWhite from '../../../assets/images/home/load_white.gif'
import { Spin } from 'antd'
import DengDiv from '@components/dengDiv'
import MintsContent from '@/components/pc/mintsContent'
import textGif from '../../../assets/images/home/text.gif'
import loadingImg from '../../../assets/images/home/kp.gif'


function AllLoad(params) {
  // loadType为1 loadWhite出现 两个页面都会使用    loadType为2 圣诞单抽页面使用     loadType为3五抽首页使用 
  const { loadType } = useSelector(state => {
    return {
      loadType: state.globalModule.loadType
    }
  })
  
  const antIcon1 = <div className='inner'>
    <img src={textGif} className="loading" />
    <div className='deng'>
      <DengDiv />
    </div>
    <div className='mint'>
      <MintsContent type={2} />
    </div>
  </div>

  const antIcon = <img src={loadingImg} className="loading1" />

  return(
    <div className='allLoad'>
      {
        loadType === '1'&&
        <>
          <img src={loadWhite} className="loadWhite" alt="" />
          <div className='maskRemark'>
            Payment is in progress.<br />Please do not close or refresh the webpage.
          </div>
        </>
      }
      {
        loadType === '2'&&
        <>
          <Spin indicator={antIcon1} className="spin1" spinning={true} />
        </>
      }
      {
        loadType === '3' &&
        <Spin indicator={antIcon} className='spin' spinning={true} />
      }
    </div>
  )
}

export default AllLoad