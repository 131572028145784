import { axios } from '@utils/request'
import Qs from 'qs'


export function create_order(issuanceId: string, quantity: string) {
    return axios({
        url: `/orders:create`,
        method: 'post',
        data: Qs.stringify({
            issuanceId,
            quantity
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

//取消失原因: 1 主动取消，2  超时未付款 , 3 支付失败
export enum ReasonEnum {
    cancel = 1,
    overduePayment = 2,
    paymentFailed = 3
}

export function cancel_order(orderId: string, reason: ReasonEnum) {
    return axios({
        url: `/orders:cancel`,
        method: 'post',
        data: Qs.stringify({
            orderId,
            reason
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}


export function set_order_paid(orderId: string, txid: string) {
    return axios({
        url: `/orders:paid`,
        method: 'post',
        data: Qs.stringify({
            orderId,
            txid
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

//交易 HASH (BSC 链可选择传此参数）
export function set_orders_setTxid(orderId: string, transactionData: string) {
    return axios({
        url: `/orders:setTxid`,
        method: 'post',
        data: Qs.stringify({
            orderId,
            transactionData
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}

export function get_collections_address(id:string){
    return axios({
        url:"/collections:address",
        method: 'get',
        params: {
            id
        }
    })
}

// /orders:mintList
export function get_orders_mintList(orderId: string, pageStart: number = 1,pageSize:number=10) {
    return axios({
        url: `/orders:mintList`,
        method: 'get',
        params: {
            orderId,
            pageStart,
            pageSize
        }
    })
}


export function set_repurchase_order(orderData:string){
    return axios({
        url:"/repurchase:order",
        method:"post",
        data: {
            orderData
        }
    })
}

export function set_all_mints(orderData:any){
    return axios({
        url:"/orders:allMints",
        method:"post",
        data: orderData
    })
}

export function set_recent () {
    // 获取最近一笔订单信息
    return axios({
        url: `/repurchase:recent`,
        method: 'get'
    })
  }

