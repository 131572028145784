import './index.scss'
import { useState, useEffect, useLayoutEffect } from "react";
import SwiperCore, { Autoplay } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import historyTwoImg from '@/assets/images/home/history-mint.png'
import DengDiv2 from '@/components/deng2Div'
import { RedoOutlined } from '@ant-design/icons';
import RuleDescription from '../ruleDescription/index'
import LuckListDialog from '../luckListDialog/index'
import ResultDialog from '../resultDialog/index'
import CollectionDialog from '../collectionDialog/index'
import BalanceDialog from '../balanceDialog/index'
import AddFunddsDialog from "../addFunddsDialog/index";
import TipsDialog from '../tipsDialog/index'
import FreeWhiteDialog from '../freeWhiteDialog'
import { useAccount, useConnectModal } from "@particle-network/connect-react-ui";
import { useDispatch, useSelector } from 'react-redux';
import { create_order_func, cancel_order_func, ReasonEnum, payDone_func } from '@features/orderClice';
import { getInfo_func, getWhiteList_Func, setLoadStat, setLoadType, issuance_Func, getHyperMint_Func } from '@/features/globalClice'
import { getImage, getBgImage } from '@utils/stringFormat'
import floorLogo from '../../../assets/images/home/element_logo.png';
import gamingLogo from '../../../assets/images/home/gameta_logo@2x.png';

SwiperCore.use([Autoplay])

const dataList = [
  { img: require('../../../assets/images/home/dash1.jpg'), cardImg: require('../../../assets/images/home/dash-kuang0.png'), priceInGame: '10', logo: require('../../../assets/images/home/gameta_logo@2x.png'), recyclingPrice: 'Gaming Value', name: 'Uncommon' },
  { img: require('../../../assets/images/home/dash2.jpg'), cardImg: require('../../../assets/images/home/dash-kuang1.png'), priceInGame: '10', logo: require('../../../assets/images/home/gameta_logo@2x.png'), recyclingPrice: 'Gaming Value', name: 'MYSTERY' }
]

function MintTwo(props) {
  const [ hyperMint, setHyperMintList ] = useState([])
  const [num, setNum] = useState(180)
  const [quantity, setQuantity] = useState(5)
  const [showRuleDescription, setShowRuleDescription] = useState(false);
  const [showLuckDialog, setShowLuckDialog] = useState(false);
  const [showBalanceDialog, setShowBalanceDialog] = useState(false);
  const [showResultDialog, setShowResultDialog ] = useState(false);
  const [showTipsDialog, setShowTipsDialog] = useState(false);
  const [showCollectionDialog, setShowCollectionDialog] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);

  const [showFreeWhiteDialog, setShowFreeWhiteDialog] = useState(false)
  const [title, setTitle] = useState('')
  const [remarkList, setRemarkList] = useState([])
  const [tipsReamrk, setTipsReamrk] = useState('');
  const [tipsBtns, setTipsBtns] = useState('');
  const [cardList, setCardList] = useState(dataList)

  const account = useAccount()
  const connectKit = useConnectModal();
  const dispatch = useDispatch();

  const { orderInfo, itemInfo, token, issuanceObj, urls } = useSelector(state => {
    return {
      orderInfo: state.orderModule.orderInfo,
      itemInfo: state.globalModule.itemInfo,
      token: state.loginModule.token,
      issuanceObj: state.globalModule.issuance,
      urls: state.globalModule.urls
    }
  }) 

  useEffect(() => {
    setHyperMint()
  }, [props.hyperMint])

  useLayoutEffect(() => {
    if (issuanceObj?.id) {
    dispatch(getInfo_func(issuanceObj.id, (list) => {
      if (list.length === 2) {
        console.log(list)
        const array = list.map((item, index) => {
          return {
            ...cardList[index],
            img: getImage(urls, item.image),
            priceInGame: Number(item.recyclingPrice) > 0 ? item.recyclingPrice : item.priceInGame,
            recyclingPrice: Number(item.recyclingPrice) > 0 ? 'Floor Price' : 'Gaming Value',
            unit: Number(item.recyclingPrice) > 0 ? 'BNB' : 'USDT',
            cardImg:getBgImage('pc', item.level,2),
            logo: Number(item.recyclingPrice) > 0 ? floorLogo : gamingLogo
          }
        })
        setCardList(array)
      }
      }))
    }
  }, [issuanceObj?.id, urls])

  useEffect(() => {
    dispatch(getWhiteList_Func())
    dispatch(issuance_Func())
    dispatch(getHyperMint_Func())
  }, [itemInfo?.id, token])

  function setHyperMint() {
    const arr = []
    const hyperMintList = [];
    const mintList = props.hyperMint
    mintList.forEach((item, index) => {
      if (index< 10) {
        hyperMintList.push(item)
      }
    })
    for (let k = 0; k < hyperMintList.length; k++) {
      const obj = {}
      obj.priceInGame = hyperMintList[k].priceInGame
      obj.recyclingPrice = hyperMintList[k].recyclingPrice
      let image = hyperMintList[k].images
      const newImage = image.split(",")
      obj.images1 = newImage[0] ? newImage[0] : ''
      obj.images2 = newImage[1] ? newImage[1] : ''
      obj.images3 = newImage[2] ? newImage[2] : ''
      obj.newBaby = hyperMintList[k].wallet.substring(0, 7) + '...' + hyperMintList[k].wallet.substring(hyperMintList[k].wallet.length - 7, hyperMintList[k].wallet.length)
      arr.push(obj)
    }
    setHyperMintList(arr)
  }

  // 点击按钮刷新库存数据
  function refreshData() {
    setNum(num + 180)
    const c = document.getElementsByClassName('refresh')
    c[0].style.transform = 'rotateZ(' + num + 'deg)'
    c[0].style.transition = 'all 0.6s'
    props.refreshIssuance()
  }

  // 打开rules弹框
  function openRulesDialog() {
    setShowRuleDescription(true)
  }
  // 关闭rules弹窗
  function closeRuleDescription() {
    setShowRuleDescription(false)
  }

  // 打开抽奖结果列表
  function openLuckDialog() {
    setShowLuckDialog(true)
  }
  function closeLuckDialog(type) {
    if (type === 1) {
      setShowLuckDialog(false)
      // setTimeout(() => {
      //   mint();
      // }, 100);
    } else {
      setShowLuckDialog(false)
    }
  }

  function openResultDialog() {
    setShowResultDialog(true)
  }
  // 关闭结果页面弹窗
  function closeResultDialog(type) {
    if (type === 1) {
      setShowResultDialog(false)
      openCollectionDialog()
    } else if (type === 2) {
      setShowResultDialog(false)
      closeLuckDialog()
      setTimeout(() => {
        mint(quantity);
      }, 100);
    } else {
      setShowResultDialog(false)
    }
  }

  // 打开收集弹窗
  function openCollectionDialog() {
    setShowCollectionDialog(true)
  }
  // 关闭收集弹框
  function closeCollectionDialog() {
    setShowCollectionDialog(false)
  }

  // 关闭余额不足弹窗
  function closeBalanceDialog(type) {
    if (type === 1) {
      setShowBalanceDialog(false)
      setShowAddDialog(true)
    } else {
      setShowBalanceDialog(false)
      dispatch(cancel_order_func(ReasonEnum.cancel, () => {
        // setShowBuyBackDialog(false);
        setShowFreeWhiteDialog(false)
      }))
    }
  }

  // 设置关闭充值弹框
  function closeAddDialog() {
    setShowAddDialog(false)
  }

  // 打开tips提示框
  function openTipsDialog() {
    setShowTipsDialog(true)
  }
  // 关闭tips提示框
  function closeTipsDialog(type) {
    if (type === 1) {
      setShowTipsDialog(false)
      setShowLuckDialog(true)
    } else {
      setShowTipsDialog(false)
    }
  }

  function mint(quantity) {
    if (account) {
      dispatch(create_order_func(quantity, (data, status) => {
        setQuantity(quantity)
        if (status) {
          if (data.order.usedWhitelist) {
            if (data.order.actualPrice === '0') {
              setTitle('Free')
              const remark = [
                'No charge for MINT', 
                `<span>Scope of application: ${itemInfo.type}</span>`, 
                `<span>MINT option: ${data.order.quantity} MINT</span>`, 
                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
              ]
              setRemarkList(remark)
            } else if(Number(data.order.actualPrice) > 0 && data.order.guaranteed === true) {
              setTitle('Breakeven')
              const remark = [
                `If the repurchase amount is less than the payment amount, the official will compensate for the loss`,
                `<span>Scope of application: ${itemInfo.type}</span>`,
                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
              ]
              setRemarkList(remark)
            } else if (Number(data.order.actualPrice) > 0 && data.order.guaranteed === false) {
              setTitle('Discount')
              const remark = [
                `Use discount price to participate in MINT`,
                `<span>Scope of application: ${itemInfo.type}</span>`,
                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
              ]
              setRemarkList(remark)
            }
            setShowFreeWhiteDialog(true)
          } else {
            dispatch(setLoadType('1'))
            dispatch(setLoadStat(true))
            window.web3.eth.getBalance(account).then(value => {
              const balance = window.web3.utils.fromWei(value, "ether");
              if (Number(data.order.totalPrice) > Number(balance)) {
                dispatch(setLoadType(''))
                dispatch(setLoadStat(false))
                setShowBalanceDialog(true)
              } else {
                dispatch(payDone_func((status) => {
                  dispatch(setLoadType(''))
                  dispatch(setLoadStat(false))
                  if (status == 0) {
                    dispatch(setLoadType('3'))
                    dispatch(setLoadStat(true))
                  }
                  if (status == 1) {
                    openResultDialog(true)
                    dispatch(issuance_Func())
                  } else if (status == 2) {
                    //failed
                    setShowLuckDialog(true)
                  } else if (status == 3) {
                    //payFailed
                    // setShowGameDialog(true)
                  } else if (status == 4) {
                    // paidFailed
                  } else if (status === 5) {
                    // 无效订单
                    openTipsDialog()
                    setTipsReamrk('This payment is abnormal,unable to mint.')
                    setTipsBtns('')
                  }
                }, () => {
                  dispatch(setLoadType(''))
                  dispatch(setLoadStat(false))
                }));
              }
            })
          }
        } else {
          if (data === 'SI060') {
            // 尚未发售订单
            openTipsDialog()
            setTipsReamrk('The time for lucky mint has not yet arrived,\n please wait patiently.')
            setTipsBtns('Confirm')
          }
          if (data === 'SI052') {
            openTipsDialog()
            setTipsReamrk(`There is an order has not been completed,\n please complete the payment first.`)
            setTipsBtns('view')
          }
          if (data === 'SI051') {
            openTipsDialog()
            setTipsReamrk(`Insufficient inventory,\n please reduce the number of lucky mint.`)
            setTipsBtns('Confirm')
          }
        }
      }))
    } else {
      connectKit.openConnectModal()
    }
  }

  function closeFreeWhiteDialog(type) {
    console.log(type);
    if (type === 0) {
      dispatch(cancel_order_func(ReasonEnum.cancel, () => {
        setShowFreeWhiteDialog(false)
      }))
    } else {
      // 此处需先判断余额是否充足若充足则出现loading动画 否则出现余额不足弹窗 
      setShowFreeWhiteDialog(false)
      window.web3.eth.getBalance(account).then(value => {
        const balance = window.web3.utils.fromWei(value, "ether");
        if (Number(orderInfo.totalPrice) > Number(balance)) {
          setShowBalanceDialog(true)
        } else {
          dispatch(setLoadType('1'))
          dispatch(setLoadStat(true))
          dispatch(payDone_func((status) => {
            dispatch(setLoadType(''))
            dispatch(setLoadStat(false))
            if (status == 0) {
              dispatch(setLoadType('3'))
              dispatch(setLoadStat(true))
            }
            if (status == 1) {
              openResultDialog(true)
              dispatch(getWhiteList_Func())
              dispatch(issuance_Func())
            } else if (status == 2) {
              //failed
              setShowLuckDialog(true)
            } else if (status == 3) {
              //payFailed
              // setShowGameDialog(true)
            } else if (status == 4) {
              // paidFailed
            } else if (status === 5) {
              // 无效订单
              openTipsDialog()
              setTipsReamrk('This payment is abnormal,unable to mint.')
              setTipsBtns('')
            }
          }, () => {
            dispatch(setLoadType(''))
            dispatch(setLoadStat(false))
          }));
        }
      });
    }
    setShowFreeWhiteDialog(false)
  }

  return(
    <div className='mintTwo'>
      <div className="gold1"></div>
      <div className="gold2"></div>
      <div className="gold3"></div>
      <div className='inner'>
        <div className='scollTop'>
          {
            hyperMint.length !==0 &&
            <Swiper
              className="swiper-container swiper-no-swiping"
              spaceBetween={0}
              slidesPerView={1}
              autoplay={{
                delay: 1000, // 默认延迟3s播放下一张
                stopOnLastSlide: false, // 播放到最后一张时停止：否
                disableOnInteraction: false // 用户操作轮播图后停止 ：是
              }}
              direction='vertical'
              loop
            >
              {
                hyperMint.map((item, index) => 
                  <SwiperSlide className="swiper-slide" key={index}>
                    <div className="baby">
                      <div className="beele">{item.newBaby}</div>
                    </div>
                    <span className="successful">Won<span className="sol">{' ' + item.recyclingPrice + ' '}</span> BNB of Lucky Mint</span>
                    <div className="prize">
                      {
                        item.images1&&<img src={item.images1} alt="" />
                      }
                      {
                        item.images2&&<img src={item.images2} alt="" />
                      }
                      {
                        item.images3&&<img src={item.images3} alt="" />
                      }
                      {
                        item.images3&&<div className="ellipsis">...</div>
                      }
                    </div>
                  </SwiperSlide>
                )
              }
            </Swiper>
          }
        </div>
        <div className='content'>
          <div className='left'>
            <DengDiv2 />
            <div className='drawContent'>
              {
                cardList.map((item, index) => 
                  <div className='card' key={index}>
                    <img className='cardImg' src={item.cardImg} alt="" />
                    <img className='imgs' src={item.img} alt="" />
                    <div className='price'>{item.priceInGame}<span>{item.unit}</span></div>
                    <div className='bottom'>
                      <img className='logo' src={item.logo} alt="" />
                      {item.recyclingPrice}
                    </div>
                  </div>
              )}
              <div className='total'>
                {
                  props.issuanceObj?
                  (props.issuanceObj.total - props.issuanceObj.sold)+ ' / ' +props.issuanceObj.total:'0/0'
                }
                <RedoOutlined onClick={refreshData} className="refresh" />
              </div>
            </div>
          </div>
          <div className='right'>
            <div className='mintPrice'>
              {
                props.issuanceObj ?
                props.issuanceObj.price : '0.3'
              }
              &nbsp;<span>BNB / MINT</span>
            </div>
            <div className='btns'>
              <div className='five'>
                <div className='fiveInner'>
                  <div className='last' onClick={() => {mint(5)}}>
                    x5<div>LUCKY MINT</div>
                  </div>
                </div>
              </div>
              <div className='one'>
                <div className='oneInner'>
                  <div className='last' onClick={() => {mint(1)}}>
                    x1<div>LUCKY MINT</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='bottom'>
              <img src={historyTwoImg} className='history' onClick={openLuckDialog} alt="" />
              <div className='rules' onClick={openRulesDialog}>
                <div className='rulesInner'>
                  Rules
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RuleDescription showRuleDescription={showRuleDescription} closeRuleDescription={closeRuleDescription} />
      <LuckListDialog showLuckDialog={showLuckDialog} closeLuckDialog={closeLuckDialog} />
      <CollectionDialog showCollectionDialog={showCollectionDialog} closeCollectionDialog={closeCollectionDialog} />
      {showResultDialog&&<ResultDialog showResultDialog={showResultDialog} closeResultDialog={(e) => { closeResultDialog(e) }} stat={0} quantity={quantity} orderInfo={orderInfo} />}
      <BalanceDialog showBalanceDialog={showBalanceDialog} closeBalanceDialog={closeBalanceDialog} />
      <AddFunddsDialog showAddDialog={showAddDialog} closeAddDialog={closeAddDialog} />
      <TipsDialog showTipsDialog={showTipsDialog} closeTipsDialog={closeTipsDialog} tipsReamrk={tipsReamrk} tipsBtns={tipsBtns} />
      <FreeWhiteDialog showFreeWhiteDialog={showFreeWhiteDialog} closeFreeWhiteDialog={closeFreeWhiteDialog} title={title} remarkList={remarkList} />
    </div>
  )
}

export default MintTwo