import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '@store/index';
import { register,refreshToken } from '../api/login' 


const LoginClice = createSlice({
    name: 'LoginModule',
    initialState: {
        token: localStorage.getItem('token')
    },
    reducers: {
        setToken(state, { payload }) {
            state.token = payload;
        }
    },
});

export const { setToken } = LoginClice.actions;

export const register_Func = (wallet: string, signature: string, networkName: string = 'bsc', cb?: Function): AppThunk => async (dispatch, getState) => {
    localStorage.setItem('networkName', networkName);
    const res: any = await register({
        wallet,
        signature,
        channelCode: '',
        networkName
    });
    if (res.success && res.data === false) {
        cb && cb(false)
    } else if (res.success && res.data) {
        localStorage.setItem('token', res.data.token);
        dispatch(setToken(res.data.token))
        cb && cb(true)
    }
}

export const disConnect_Func = (): AppThunk => async (dispatch, getState) => {
    setTimeout(() => {
        localStorage.removeItem('token');
    }, 1000)
    dispatch(setToken(null));
}

export const refreshToken_Func = ():AppThunk =>async  (dispatch, getState) => {
    const res:any=await refreshToken();
    if (res.success && res.data === false) { 
    } else if (res.success && res.data) {
        localStorage.setItem('token', res.data.token);
        dispatch(setToken(res.data.token)) 
    }else{
        localStorage.removeItem('token');
        dispatch(setToken(null)) 
    }

}


export default LoginClice.reducer;