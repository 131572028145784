import './index.scss'
import { Modal, Pagination } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useState, useEffect } from 'react'
import { get_result_func } from '../../../features/orderClice'
import { useDispatch, useSelector } from "react-redux";
import { useParticleProvider } from "@particle-network/connect-react-ui";
import OpenSea_Full_Logo from '@assets/images/home/element_logo.png';
import wenhaoImg from '@assets/images/home/wenhao.png';
import {config} from '@/config'

function HowToSell() {
  return <a className='how_to_sell' target="_blank" href={config.sellUrl}> <img src={wenhaoImg} />  How to sell</a>
}

function ResultDialog(props) {
  const { chainId } = useSelector(state => {
    return {
      chainId: state.globalModule.chain?.id
    }
  })
  const metadata = useSelector(state => {
    return state.globalModule.urls
  })
  const provider = useParticleProvider();
  const [singleObj, setSingleObj] = useState(null)
  const [floorValue, setFloorValue] = useState(0)
  const [gametaValue, setGametaValue] = useState(0)
  const [gmbList, setGmbList] = useState([])
  const [newHm, setNewHm] = useState([])
  const dispatch = useDispatch();
  const issuanceType = sessionStorage.getItem('issuanceType')

  const [current, setCurrent] = useState(1);
  // 手动分页
  function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return <div className='nextBtn'>Last page</div>;
    }
    if (type === 'next') {
      return <div className='nextBtn'>Next page</div>;
    }
    return originalElement;
  }

  function handleCancel(e) {
    props.closeResultDialog(e)
  }
  function againGame() {
    handleCancel(2)
  }
  function closeDilog() {
    window.open(config.elementAccountUrl);
  }
  function share() {
    window.open(config.twitterHomeUrl)
  }
  useEffect(() => {
    if (props.showResultDialog) {
      setSingleObj(null)
      setGmbList([])
      setGametaValue(0)
      setFloorValue(0)
      setNewHm([])
      if (gmbList.length === 30) {
        setCurrent(1, 10, gmbList)
      }
      init && init()
    }
  }, [props.showResultDialog])
  function init() {
    dispatch(get_result_func(chainId, props.orderInfo.order.id, metadata, (data, type, gametaValue, floorValue) => {
      if (type) {
        setSingleObj(data)
      } else {
        setGmbList(data)
        setGametaValue(gametaValue)
        setFloorValue(floorValue)
        onChange(1, 10, data)
      }
    }))
  }
  // 河马设置手动分页器
  function onChange(page, pageSize, data) {
    setCurrent(page)
    const newData = data === undefined ? gmbList : data
    let start = pageSize * page - 10
    let end = pageSize * page
    setNewHm([...newData.slice(start, end)])
  }
  return (
    <Modal className='result' footer={null} title={null} open={props.showResultDialog} keyboard={false} maskClosable={false} wrapClassName='resultDialog' getContainer="false" onCancel={(e)=>{handleCancel(e)}}>
      <div className="resultContent">
        <div className="title"></div>
        <div className="sellTopImg"></div>
        <div className="jb1"></div>
        <div className="jb2"></div>
        <div className="congratulations">congratulations</div>
        <div className="share" onClick={share}>SHARE</div>
        {/* 抽单个*/}
        {
          props.quantity === 1 &&
          <div className="only">
            {
              singleObj !== null ?
                <div>
                  {
                    singleObj.manghe === 1 ?
                      <div className="one">
                        <div className="sellTop">Won <span className="price">{singleObj.priceInGame + ' '}</span>USDT (Gaming Value)</div>
                        <div className="delayed">If the network is delayed, the NFTs will arrive at the wallet in 5-15 minutes.</div>
                        <img src={singleObj.image} alt="" />
                        <div className="sellName">{singleObj.newName}</div>
                        <div className="gamingOne">Gaming Value:<br />{singleObj.priceInGame + ' '} USDT</div>
                        {
                          issuanceType === '1of2' && props.stat !== undefined &&
                          <div className='game' onClick={againGame}>CONTINUE MINTING</div>
                        }
                      </div> :
                      <div className="two">
                        <img src={singleObj.bgImage} className="bgImage" alt="" />
                        <div className="sellTop">Won <span className="price">{singleObj.recyclingPrice + ' '}</span>BNB</div>
                        <div className="delayed">If the network is delayed, the NFTs will arrive at the wallet in 5-15 minutes.</div>
                        <img src={singleObj.image} alt="" />
                        <div className="sellName">{singleObj.newName}</div>
                        <div className="disSell"><span className="disPrice">Floor Price:</span><br />{singleObj.recyclingPrice + ' '}BNB</div>
                        <div className='saleBtns'>
                        {
                          issuanceType === '1of2' && props.stat !== undefined ?
                          <>
                            <div className='bottomBtn'>
                              <HowToSell />
                              <div className='kong' onClick={closeDilog} ><img src={OpenSea_Full_Logo} />LIST ON ELEMENT </div>
                            </div>
                            <div className='game' onClick={againGame}>CONTINUE MINTING</div>
                          </>:
                          <>
                            <div className='sellBtn' onClick={closeDilog} ><img src={OpenSea_Full_Logo} />LIST ON ELEMENT </div>
                            <HowToSell />
                          </>
                        }
                          {/*  */}
                        </div>
                      </div>
                  }
                </div> :
                <LoadingOutlined className='loadIcon' />
            }
          </div>
        }
        {
          // 抽五个 
          props.quantity === 5 &&
          <div className="only">
            <div className="sellTop">Won
              {
                floorValue !== 0 &&
                <span><span className="price">{' ' + floorValue.toFixed(5) + ' '}</span>BNB &nbsp;</span>
              }
              {
                gametaValue !== 0 &&
                <span>+ &nbsp;<span className="price">{' ' + gametaValue.toFixed(2) + ' '}</span>USDT &nbsp; (Gaming Value)</span>
              }
            </div>
            <div className="delayed">If the network is delayed, the NFTs will arrive at the wallet in 5-15 minutes.</div>
            <div className="gmbStyle">
              {
                gmbList.length === 0 ?
                  <LoadingOutlined className='loadIcon5' /> :
                  <div className='list'>
                    {
                      gmbList.map((item, index) =>
                        <div className='itemStyle' key={index}>
                          {
                            item.manghe === 1 ?
                              <div className="resultImg">
                                <img src={item.image} alt="" />
                                <div className="sellName">{item.name}</div>
                                <div className="gaming"><span className='gameValue'>Gaming Value:</span><br />{item.priceInGame + ' '} USDT</div>
                              </div> :
                              <div className="resultHm">
                                <img src={item.bgImage} className="bgImage" alt="" />
                                <img src={item.image} alt="" />
                                <div className="sellName">{item.name}</div>
                                <div className="disSell"><span className="disPrice">Floor Price:</span><br />{item.recyclingPrice + ' '}BNB</div>
                              </div>
                          }
                        </div>
                      )
                    }
                    <div className='saleBtns'>
                      <div className='sellBtn' onClick={closeDilog} ><img src={OpenSea_Full_Logo} />LIST ON ELEMENT </div>
                      <HowToSell />
                      {/* <div className='game'>CONTINUE MINTING</div> */}
                    </div>
                  </div>
              }
            </div>
          </div>
        }
        {
          // 抽10个
          props.quantity === 10 &&
          <div className='only'>
            <div className="sellTop">Won
              {
                floorValue !== 0 &&
                <span><span className="price">{' ' + floorValue.toFixed(5) + ' '}</span>BNB &nbsp; </span>
              }
              {
                gametaValue !== 0 &&
                <span> + &nbsp;<span className="price">{' ' + gametaValue.toFixed(2) + 'USDT'}</span> USDT &nbsp; (Gaming Value)</span>
              }
            </div>
            <div className="delayed">If the network is delayed, the NFTs will arrive at the wallet in 5-15 minutes.</div>
            <div className="gmbStyle10">
              {
                gmbList.length === 0 ?
                  <LoadingOutlined className='loadIcon10' /> :
                  <div className='list'>
                    {
                      gmbList.map((item, index) =>
                        <div className='gmbListStyle' key={index}>
                          {
                            item.manghe === 1 ?
                              <div className="resultImg">
                                <img src={item.image} alt="" />
                                <div className="sellName">{item.name}</div>
                                <div className="gaming">Gaming Value:<br />{item.priceInGame + ' '} USDT</div>
                              </div> :
                              <div className="resultHm">
                                <img src={item.bgImage} className="bgImage" alt="" />
                                <img src={item.image} alt="" />
                                <div className="sellName">{item.name}</div>
                                <div className="disSell"><span className="disPrice">Floor Price:</span><br />{item.recyclingPrice + ' '}BNB</div>
                              </div>
                          }
                        </div>
                      )
                    }
                    <div className='saleBtns'>
                      <div className='sellBtn' onClick={closeDilog} ><img src={OpenSea_Full_Logo} />LIST ON ELEMENT </div>
                      <HowToSell />
                    </div>
                  </div>
              }
            </div>
          </div>
        }
        {
          //抽30个
          props.quantity === 30 &&
          <div className='only1'>
            <div className="sellTop">Won
              {
                floorValue !== 0 &&
                <span><span className="price">{' ' + floorValue.toFixed(5) + ' '}</span>BNB &nbsp; </span>
              }
              {
                gametaValue !== 0 &&
                <span> + &nbsp;<span className="price">{' ' + gametaValue.toFixed(2) + ' '}</span>USDT &nbsp; (Gaming Value)</span>
              }
            </div>
            <div className="delayed">If the network is delayed, the NFTs will arrive at the wallet in 5-15 minutes.</div>
            <div className="gmbStyle30">
              {
                newHm.length === 0 ?
                  <LoadingOutlined className='loadIcon30' /> :
                  <div className="hm">
                    {
                      newHm.map((item, index) =>
                        <div className='allHm' key={index}>
                          {
                            item.manghe === 1 ?
                              <div className='mhList'>
                                <img src={item.image} className="hmImg" alt="" />
                                <div className="hmName">{item.name}</div>
                                <div className="sell">Gaming Value:<br />{item.priceInGame + ' '}USDT</div>
                              </div> :
                              <div className="hmList">
                                <img src={item.bgImage} className="bgImage" alt="" />
                                <img src={item.image} className="hmImg" alt="" />
                                <div className="hmName">{item.name}</div>
                                <div className="sell">Floor Price:<br />{item.recyclingPrice + " "}BNB</div>
                              </div>
                          }
                        </div>
                      )
                    }
                    <Pagination
                      className='pagination'
                      defaultPageSize={10}
                      current={current}
                      total={30}
                      itemRender={itemRender}
                      onChange={onChange}
                    />
                    <div className='saleBtns'>
                      <div className='sellBtn' onClick={closeDilog} ><img src={OpenSea_Full_Logo} />LIST ON ELEMENT </div>
                      <HowToSell />
                    </div>
                  </div>
              }
            </div>
          </div>
        }
      </div>
    </Modal>
  )
}

export default ResultDialog