import './index.scss'
import snowImg from '@/assets/images/home/snow.png'
import snowMan from '@/assets/images/home/snowman.png' 
import tableImg from '@/assets/images/home/table.png'
import { useEffect, useState } from 'react'
import textImg from '@/assets/images/home/text.png'
import leftImg from '@/assets/images/home/leftLine.png'
import rightImg from '@/assets/images/home/rightLine.png'
import sunImg from '@/assets/images/home/sun.png'
import giftImg from '@/assets/images/home/gifts.png'
import Footer from '@/components/pc/pcFooter'
import AwardDialog from '../awardDialog'
import RecordDialog from '../recordDialog'
// import SwiperCore from 'swiper/core' // 引入核心插件和自动播放组件
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { ActivitiesType, getActivities_func, awardLotteryCount_func, getNFTQueryStatus_func, awardLottery_func, receiveNft_func, setMintCount } from '@/features/activitiesClice'
import { useAccount, useConnectModal } from '@particle-network/connect-react-ui'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import moment from 'moment'
import hippo from '@/assets/images/home/rectangle.png'
import { useLocation, useNavigate } from 'react-router-dom'
import TipsDialog from '@/components/pc/tipsDialog'
import { RedoOutlined } from '@ant-design/icons'; 
import lingdangGif from '@/assets/images/home/lingdang.gif'


const timeFormer = "YYYY.MM.DD HH:mm"
const remarkList = [
  'Exclusive VIP activity (having the opportunity to get Hippo NFT airdrop)',
  'Cultural and creative products around Hippo official entity',
  'Offline party for global Hippo holders',
  'Brand cooperation resources',
  'Airdrop on the white list of cooperative project parties'
]
function Index() {

  const navigate = useNavigate()

  const [btnStat, setBtnStat] = useState(0)
  const [btnText, setBtnText] = useState('Free Mint')
  const [showAwardDialog, setShowAwardDialog] = useState(false);
  const [awardData, setAwardData] = useState(null);
  const [showRecordDialog, setShowRecordDialog] = useState(false);
  const [raffleNumber, setRaffleNumber] = useState(0);
  const [showTipsDialog, setShowTipsDialog] = useState(false);
  const [tipsReamrk, setTipsReamrk] = useState('');
  const [tipsBtns, setTipsBtns] = useState('');
  const [refreshLoading, setRefreshLoading] = useState(false)
  const account = useAccount()
  const dispatch = useDispatch();
  const connectKit: any = useConnectModal();

  const [selectedContract, setSelectedContract] = useState<any>(null);

 

  const { chain, activitiesInfo, contractBalance, mintCount, contractBalanceList, activitiesInfo2, token }: {
    chain: any,
    activitiesInfo: any,
    activitiesInfo2: any,
    contractBalance: Number,
    mintCount: Number,
    contractBalanceList: any[],
    token: any
  } = useSelector((state: RootState) => {
    return {
      chain: state.globalModule.chain,
      activitiesInfo: state.activitiesModule.activitiesInfo,
      activitiesInfo2: state.activitiesModule.activitiesInfo2,
      contractBalance: state.activitiesModule.contractBalance,
      contractBalanceList: state.activitiesModule.contractBalanceList,
      mintCount: state.activitiesModule.mintCount,
      token: state.loginModule.token
    }
  })
  function award() {
    dispatch(awardLottery_func(selectedContract.tokenId, (data: any) => {
      // console.log(data, "=======awardLottery_func==")
      setAwardData(data);
      setShowAwardDialog(true)
    }))
    // setShowAwardDialog(true)
  }
  function closeAwardDialog(type: number) {
    getAwardLotteryCount(selectedContract.tokenId)
    if (type === 1) {
      setShowRecordDialog(true)
    } else {
      setShowAwardDialog(false)
    }
  }

  // 关闭tips提示框
  function closeTipsDialog() {
    setShowTipsDialog(false)
  }

  function record() {
    setShowRecordDialog(true)
  }
  function closeRecordDialog() {
    setShowRecordDialog(false)
  }

  useEffect(() => {
    if (mintCount && activitiesInfo) {
      if (activitiesInfo.left === 0) {   //库存不足 
        console.log("库存不足");
        setBtnStat(0);
        setBtnText('Free Mint');
      } else if (activitiesInfo.status === 0) {   //圣诞活动未开始
        console.log("圣诞活动未开始");
        setBtnStat(0);
        setBtnText('Free Mint');
      } else if (Number(mintCount) - Number(activitiesInfo.mintTimes) < 0) {    // mint次数小于 5
        console.log("mint次数小于 5");
        setBtnStat(0);
        setBtnText('Free Mint');
      } else {
        setBtnStat(1);
        setBtnText('Free Mint');
      }
      // setBtnStat((Number(mintCount) - Number(activitiesInfo.mintTimes)))
    }
  }, [mintCount, contractBalanceList, activitiesInfo, raffleNumber])
  useEffect(() => { 
    dispatch(getActivities_func(ActivitiesType.getHipp, chain?.id, account?[account]:[]))
    dispatch(getActivities_func(ActivitiesType.luckDrawActivity, chain?.id, account?[account]:[]))
    if (account && chain?.id && token) { 
    } else if (!account && !token) {
      console.log((!account && !token), account, token, "====!account && !token")
      setBtnStat(0)
      dispatch(setMintCount(0))
      connectKit.openConnectModal();
    }
  }, [account, chain?.id, token]);


  useEffect(() => {
    if (account && token) {
      if (contractBalanceList && contractBalanceList.length > 0) {
        //跳转到抽奖页
        navigate("/activity", {
          replace: true
        })
      } else {
        getNFTQueryStatus()
      }
    }
  }, [contractBalanceList, account, token])

  useEffect(()=> {
    
  }, [])

  const getNFTQueryStatus = () => {
    //-1 无nft  0 初始化，1 minting中 2 mint 完成  3 mint失败
    if (account) {
      dispatch(getNFTQueryStatus_func((data: any) => {
        if (data == 2) {
          //跳转到抽奖页
          navigate("/activity", {
            replace: true
          })
        } else if (data == 1 || data == 0) {
          console.log("Minting");
          setBtnStat(0);
          setBtnText('Minting');
        } else if (data == 3) {
          setShowTipsDialog(true)
          setTipsReamrk(`Network exception, please try again.`)
          setTipsBtns('view')
        }

      }))
    }
  }

  const getAwardLotteryCount = (tokenId: any) => {
    dispatch(awardLotteryCount_func(tokenId, (data: any) => {
      setRaffleNumber(data);
    }))
  }


  const receiveNft = () => {
    dispatch(receiveNft_func((data: any) => {
      if (data === 1) {
        getNFTQueryStatus()
      } else if (data === 'SI083') {
        setShowTipsDialog(true)
        setTipsReamrk(`Already received.`)
        setTipsBtns('view')
      }
    }))
  }
  // 点击按钮刷新库存数据
  function refreshData() {
    if (account) {
      setRefreshLoading(true)
      dispatch(getActivities_func(ActivitiesType.getHipp, chain?.id, [account], undefined, () => {
        setRefreshLoading(false)
      }))
    }
    // const c = document.getElementsByClassName('refresh1')
    // console.log(c[0].style);

    // c[0].style.transform = 'rotateZ(' + num + 'deg)'
    // c[0].style.transition = 'all 0.6s'
  }

  return (
    <div className="christmas">
      <div className='inner'>
        <div className='activeTop'>
          <div className='left'>
            <div>
              <div className='remark'><span>Hippo VIP NFT</span><br />Free Mint For Hippo Fans Who Have Been {activitiesInfo?.mintTimes}Times for Mint</div>
              <div className='middle'>{activitiesInfo?.total} Hippo VIP NFT<br />FCFS</div>
              <div className='time'>Campain Time:<br />{activitiesInfo && moment(activitiesInfo.startTime).format(timeFormer)} - {activitiesInfo && moment(activitiesInfo.endTime).format(timeFormer)}</div>
            </div>
            <img src={snowImg} className="snow" alt="" />
            <img src={snowMan} className="man" alt="" />
          </div>
          <div className='right'>
            <div className='swiper-nft swiper-no-swiping'>
              <div className="swiper-slide zebra" style={{ margin: 'auto' }}>
                <img src={hippo} className="nftsImg" alt="" />
              </div>
            </div>
            <div>
              <div className='stock'>{activitiesInfo?.left}/{activitiesInfo?.total}
                <span>
                  <RedoOutlined spin={refreshLoading} onClick={refreshData} className="refresh1" style={{ marginLeft: '8px' }} />
                </span>
              </div>
              {
                btnStat === 0 ?
                  // Transferred out 抽奖成功提示
                  <div className='activeButton'>{btnText}</div> :
                  <div className='lightButton' onClick={receiveNft}>{btnText}</div>
              }
              <div className='mintEnd'>My MINT times: <>{mintCount}</></div>
              <img src={tableImg} className="table" alt="" />
            </div>
            <img src={lingdangGif} className='lingdang' onClick={()=> {
              navigate('/hippoclub')
            }} alt="" />
          </div>
        </div>
        <div className='activeBottom' style={{ height: '1190px' }}>
          <div className='title'>
            <img src={leftImg} alt="" />
            <img src={textImg} alt="" />
            <img src={rightImg} alt="" />
          </div>
          <div className='receive'>During the campaign period, Hippo VIP can claim rewards every day<br />（{activitiesInfo2 && moment(activitiesInfo2.startTime).format(timeFormer)} - {activitiesInfo2 && moment(activitiesInfo2.endTime).format(timeFormer)}）</div>

          <Swiper
            modules={[Navigation]}
            className="swiper-container1 swiper-no-swiping"
            spaceBetween={-34}
            slidesPerView={4}
            loop
            navigation
          >
            {
              activitiesInfo2 && activitiesInfo2.awards.map((item: any, index: any) =>
                <SwiperSlide className="swiper-slide prizeBox" key={index}>
                  <div className='box'></div>
                  <img src={item.image} className="kuang" alt="" />
                  <div className='prizeBtn'>{item.name}</div>
                </SwiperSlide>
              )
            }
          </Swiper>

          <img src={giftImg} className='gift' alt="" />
          <div className='rule'>
            {
              remarkList.map((item, index) =>
                <div key={index}><img src={sunImg} alt="" /> {item} <img src={sunImg} alt="" /></div>
              )
            }
          </div>
        </div>
        <Footer />
      </div>
      <AwardDialog showAwardDialog={showAwardDialog} data={awardData} closeAwardDialog={closeAwardDialog} />
      <RecordDialog showRecordDialog={showRecordDialog} closeRecordDialog={closeRecordDialog} />
      <TipsDialog showTipsDialog={showTipsDialog} closeTipsDialog={closeTipsDialog} tipsReamrk={tipsReamrk} tipsBtns={tipsBtns} />
    </div>
  )
}

export default Index