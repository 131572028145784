import './index.scss'
import React, { useState, useEffect } from "react";
import SwiperCore, { Autoplay } from 'swiper/core' // 引入核心插件和自动播放组件
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import DengDiv from '@components/dengDiv'
import snowBtnsImg from '@/assets/images/home/snow-btns.png'
import { RedoOutlined, RightOutlined } from '@ant-design/icons';
import RuleDescription from '../ruleDescription/index'
import MintsContent from '../mintsContent'
import { useAccount, useConnectModal } from "@particle-network/connect-react-ui";
import { useDispatch, useSelector } from 'react-redux';
import { create_order_func, seaport_listing_func, get_NFTs_func } from '@features/orderClice';
import { getInfo_func, getWhiteList_Func, setLoadStat, setLoadType, issuance_Func, getHyperMint_Func } from '@/features/globalClice'
import { cancel_order_func, ReasonEnum, payDone_func } from '@features/orderClice';
import TipsDialog from '../tipsDialog/index'
import LuckListDialog from '../luckListDialog/index'
import ResultDialog from '../resultDialog/index'
import CollectionDialog from '../collectionDialog/index'
import BalanceDialog from '../balanceDialog/index'
import AddFunddsDialog from "../addFunddsDialog/index";
import historyImg from '@/assets/images/home/history.png'
import { useNavigate } from "react-router-dom";
import FreeWhiteDialog from '../freeWhiteDialog'
import snowImg from '../../../assets/images/home/snow.png'

SwiperCore.use([Autoplay])

function ChristmasMint(props) {
  const [hyperMint, setHyperMintList] = useState([])
  const [num, setNum] = useState(180)
  const [showRuleDescription, setShowRuleDescription] = useState(false);
  const [showTipsDialog, setShowTipsDialog] = useState(false);
  const [showLuckDialog, setShowLuckDialog] = useState(false);
  const [showResultDialog, setShowResultDialog] = useState(false);
  const [showCollectionDialog, setShowCollectionDialog] = useState(false);
  const [showBalanceDialog, setShowBalanceDialog] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showFreeWhiteDialog, setShowFreeWhiteDialog] = useState(false)
  const [title, setTitle] = useState('')
  const [remarkList, setRemarkList] = useState([])
  const [tipsReamrk, setTipsReamrk] = useState('');
  const [tipsBtns, setTipsBtns] = useState('');
  const account = useAccount();
  const dispatch = useDispatch();
  const connectKit = useConnectModal();
  const navigate = useNavigate();

  const { chainId, itemInfo, token } = useSelector(state => {
    return {
      chainId: state.globalModule.chain,
      itemInfo: state.globalModule.itemInfo,
      token: state.loginModule.token
    }
  }) 

  const orderInfo = useSelector(state => {
    return state.orderModule.orderInfo
  })

  useEffect(() => {
    setHyperMint()
  }, [props.hyperMint])

 

  useEffect(() => {
    dispatch(getWhiteList_Func())
    dispatch(issuance_Func())
    dispatch(getHyperMint_Func())
  }, [itemInfo?.id, token])

  function setHyperMint() {
    const arr = []
    const hyperMintList = [];
    const mintList = props.hyperMint
    mintList.forEach((item, index) => {
      if (index < 10) {
        hyperMintList.push(item)
      }
    })
    for (let k = 0; k < hyperMintList.length; k++) {
      const obj = {}
      obj.priceInGame = hyperMintList[k].priceInGame
      obj.recyclingPrice = hyperMintList[k].recyclingPrice
      let image = hyperMintList[k].images
      const newImage = image.split(",")
      obj.images1 = newImage[0] ? newImage[0] : ''
      obj.images2 = newImage[1] ? newImage[1] : ''
      obj.images3 = newImage[2] ? newImage[2] : ''
      obj.newBaby = hyperMintList[k].wallet.substring(0, 7) + '...' + hyperMintList[k].wallet.substring(hyperMintList[k].wallet.length - 7, hyperMintList[k].wallet.length)
      arr.push(obj)
    }
    setHyperMintList(arr)
  }

  // 打开rules弹框
  function openRulesDialog() {
    setShowRuleDescription(true)
  }
  // 关闭rules弹窗
  function closeRuleDescription() {
    setShowRuleDescription(false)
  }
  // 打开tips提示框
  function openTipsDialog() {
    setShowTipsDialog(true)
  }
  // 关闭tips提示框
  function closeTipsDialog(type) {
    if (type === 1) {
      setShowTipsDialog(false)
      setShowLuckDialog(true)
    } else {
      setShowTipsDialog(false)
    }
  }
  // 打开抽奖结果列表
  function openLuckDialog() {
    setShowLuckDialog(true)
  }
  function closeLuckDialog(type) {
    if (type === 1) {
      setShowLuckDialog(false)
      setTimeout(() => {
        mint();
      }, 100);
    } else {
      setShowLuckDialog(false)
    }
  }
  function openResultDialog() {
    setShowResultDialog(true)
  }
  // 关闭结果页面弹窗
  function closeResultDialog(type) {
    if (type === 1) {
      setShowResultDialog(false)
      openCollectionDialog()
    } else if (type === 2) {
      setShowResultDialog(false)
      closeLuckDialog()
      setTimeout(() => {
        mint();
      }, 100);
    } else {
      setShowResultDialog(false)
    }
  }

  // 打开收集弹窗
  function openCollectionDialog() {
    setShowCollectionDialog(true)
  }
  // 关闭收集弹框
  function closeCollectionDialog() {
    // if (type === 1) {
    //   openTipsDialog()
    //   setTipsReamrk('This payment is abnormal,unable to mint.')
    //           setTipsBtns('')
    // }
    setShowCollectionDialog(false)
  }

  // 关闭余额不足弹窗
  function closeBalanceDialog(type) {
    if (type === 1) {
      setShowBalanceDialog(false)
      setShowAddDialog(true)
    } else {
      setShowBalanceDialog(false)
      dispatch(cancel_order_func(ReasonEnum.cancel, () => {
        // setShowBuyBackDialog(false);
        setShowFreeWhiteDialog(false)
      }))
    }
  }

  // 设置关闭充值弹框
  function closeAddDialog() {
    setShowAddDialog(false)
  }

  function closeFreeWhiteDialog(type) {
    if (type === 0) {
      dispatch(cancel_order_func(ReasonEnum.cancel, () => {
        setShowFreeWhiteDialog(false)
      }))
    } else {
      // 此处需先判断余额是否充足若充足则出现loading动画 否则出现余额不足弹窗 
      setShowFreeWhiteDialog(false)
      window.web3.eth.getBalance(account).then(value => {
        const balance = window.web3.utils.fromWei(value, "ether");
        if (Number(orderInfo.totalPrice) > Number(balance)) {
          setShowBalanceDialog(true)
        } else {
          dispatch(setLoadType('1'))
          dispatch(setLoadStat(true))
          dispatch(payDone_func((status) => {
            dispatch(setLoadType(''))
            dispatch(setLoadStat(false))
            if (status == 0) {
              dispatch(setLoadType('2'))
              dispatch(setLoadStat(true))
            }
            if (status == 1) {
              openResultDialog(true)
              dispatch(getWhiteList_Func())
              dispatch(issuance_Func())
            } else if (status == 2) {
              //failed
              setShowLuckDialog(true)
            } else if (status == 3) {
              //payFailed
              // setShowGameDialog(true)
            } else if (status == 4) {
              // paidFailed
            } else if (status === 5) {
              // 无效订单
              openTipsDialog()
              setTipsReamrk('This payment is abnormal,unable to mint.')
              setTipsBtns('')
            }
          }, () => {
            dispatch(setLoadType(''))
            dispatch(setLoadStat(false))
          }));
        }
      });
      
    }
    setShowFreeWhiteDialog(false)
  }

  function mint() {
    if (account) {
      dispatch(create_order_func(1, (data, status) => {
        if (status) {
          // 先判断是否是白名单补偿
          if (data.order.usedWhitelist) {
            // setShowBuyBackDialog(true)
            if (data.order.actualPrice === '0') {
              setTitle('Free')
              const remark = [
                'No charge for MINT', 
                `<span>Scope of application: ${itemInfo.type}</span>`, 
                `<span>MINT option: ${data.order.quantity} MINT</span>`, 
                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
              ]
              setRemarkList(remark)
            } else if(Number(data.order.actualPrice) > 0 && data.order.guaranteed === true) {
              setTitle('Breakeven')
              const remark = [
                `If the repurchase amount is less than the payment amount, the official will compensate for the loss`,
                `<span>Scope of application: ${itemInfo.type}</span>`,
                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
              ]
              setRemarkList(remark)
            } else if (Number(data.order.actualPrice) > 0 && data.order.guaranteed === false) {
              setTitle('Discount')
              const remark = [
                `Use discount price to participate in MINT`,
                `<span>Scope of application: ${itemInfo.type}</span>`,
                `<span>MINT option: ${data.order.quantity} MINT</span>`,
                `<div class='label'>MINT Total Price: ${data.order.actualPrice} (<span>${Number(itemInfo.price) * data.order.quantity}</span>) BNB/MINT</div>`
              ]
              setRemarkList(remark)
            }
            setShowFreeWhiteDialog(true)
          } else {
            dispatch(setLoadType('1'))
            dispatch(setLoadStat(true))
            // 不是直接调用支付（此时用户为普通用户）
            window.web3.eth.getBalance(account).then(value => {
              const balance = window.web3.utils.fromWei(value, "ether");
              if (Number(data.order.totalPrice) > Number(balance)) {
                // dispatch(cancel_order_func(ReasonEnum.cancel, () => {
                //   setShowBuyBackDialog(false);
                // }))
                dispatch(setLoadType(''))
                dispatch(setLoadStat(false))
                setShowBalanceDialog(true)
              } else {
                dispatch(payDone_func((status) => {
                  dispatch(setLoadType(''))
                  dispatch(setLoadStat(false))
                  if (status == 0) {
                    dispatch(setLoadType('2'))
                    dispatch(setLoadStat(true))
                  }
                  if (status == 1) {
                    openResultDialog(true)
                    dispatch(issuance_Func())
                  } else if (status == 2) {
                    //failed
                    setShowLuckDialog(true)
                  } else if (status == 3) {
                    //payFailed
                    // setShowGameDialog(true)
                  } else if (status == 4) {
                    // paidFailed
                  } else if (status === 5) {
                    // 无效订单
                    openTipsDialog()
                    setTipsReamrk('This payment is abnormal,unable to mint.')
                    setTipsBtns('')
                  }
                }, () => {
                  dispatch(setLoadType(''))
                  dispatch(setLoadStat(false))
                }));
              }
            });
          }
        } else {
          if (data === 'SI060') {
            // 尚未发售订单
            openTipsDialog()
            setTipsReamrk('The time for lucky mint has not yet arrived,\n please wait patiently.')
            setTipsBtns('Confirm')
          }
          if (data === 'SI052') {
            openTipsDialog()
            setTipsReamrk(`There is an order has not been completed,\n please complete the payment first.`)
            setTipsBtns('view')
          }
          if (data === 'SI051') {
            openTipsDialog()
            setTipsReamrk(`Insufficient inventory,\n please reduce the number of lucky mint.`)
            setTipsBtns('Confirm')
          }
        }
      }))
    } else {
      // connectKit.connect()
      connectKit.openConnectModal()
    }
  }

  // function activityPage() {
  //   navigate('/hippovip')
  // }

  // 点击按钮刷新库存数据
  function refreshData() {
    setNum(num + 180)
    const c = document.getElementsByClassName('refresh')
    c[0].style.transform = 'rotateZ(' + num + 'deg)'
    c[0].style.transition = 'all 0.6s'
    props.refreshIssuance()
  }

  return (
    <div className='christmasMint'>
      <div className='inner'>
        <div className="snowLeft">
          <img src={snowImg} className='snowImg1' alt="" />
          <img src={snowImg} className='snowImg2' alt="" />
          <img src={snowImg} className='snowImg3' alt="" />
        </div>
        <div className="snowRight">
          <img src={snowImg} className='snowImg1' alt="" />
          <img src={snowImg} className='snowImg2' alt="" />
          <img src={snowImg} className='snowImg3' alt="" />
        </div>
        <div className='scollTop'>
          {
            hyperMint.length !== 0 &&
            <Swiper
              className="swiper-container swiper-no-swiping"
              spaceBetween={0}
              slidesPerView={1}
              autoplay={{
                delay: 1000, // 默认延迟3s播放下一张
                stopOnLastSlide: false, // 播放到最后一张时停止：否
                disableOnInteraction: false // 用户操作轮播图后停止 ：是
              }}
              direction='vertical'
              loop
            >
              {
                hyperMint.map((item, index) =>
                  <SwiperSlide className="swiper-slide" key={index}>
                    <div className="baby">
                      <div className="beele">{item.newBaby}</div>
                    </div>
                    <span className="successful">Won<span className="sol">{' ' + item.recyclingPrice + ' '}</span> BNB of Lucky Mint</span>
                    <div className="prize">
                      {
                        item.images1 && <img src={item.images1} alt="" />
                      }
                      {
                        item.images2 && <img src={item.images2} alt="" />
                      }
                      {
                        item.images3 && <img src={item.images3} alt="" />
                      }
                      {
                        item.images3 && <div className="ellipsis">...</div>
                      }
                    </div>
                  </SwiperSlide>
                )
              }
            </Swiper>
          }
        </div>
        <DengDiv />
        <MintsContent type={1} />
        <div className="drawNumber">
          <div className="first">
            <div className="total">
              {
                props.issuanceObj ?
                  (props.issuanceObj.total - props.issuanceObj.sold) + '/' + props.issuanceObj.total : '0/0'
              }
              <RedoOutlined onClick={refreshData} className="refresh" />
            </div>
          </div>
          <div className="middle" onClick={openRulesDialog}>Rules<RightOutlined className='right' /></div>
        </div>
        {/* <img src={enterGif} className='enter' onClick={activityPage} alt="" /> */}
        <div className='snowBtns' onClick={() => {
          mint()
        }}>
          <img src={snowBtnsImg} alt="" />
        </div>
        <img src={historyImg} className='history' onClick={openLuckDialog} alt="" />
        <div className='mintPrice'>
          {
            props.issuanceObj ?
              props.issuanceObj.price : '0.3'
          }
          &nbsp;<span>BNB/MINT</span>
        </div>
      </div>
      {/* <BuyBackDialog showBuyBackDialog={showBuyBackDialog} closeBuyBackDialog={closeBuyBackDialog} buyStat={buyStat} /> */}
      <RuleDescription showRuleDescription={showRuleDescription} closeRuleDescription={closeRuleDescription} />
      <LuckListDialog showLuckDialog={showLuckDialog} closeLuckDialog={closeLuckDialog} />
      {showResultDialog && <ResultDialog showResultDialog={showResultDialog} closeResultDialog={(e) => { closeResultDialog(e) }} quantity={1} orderInfo={orderInfo} />}
      <CollectionDialog showCollectionDialog={showCollectionDialog} closeCollectionDialog={closeCollectionDialog} />
      <BalanceDialog showBalanceDialog={showBalanceDialog} closeBalanceDialog={closeBalanceDialog} />
      <AddFunddsDialog showAddDialog={showAddDialog} closeAddDialog={closeAddDialog} />
      <TipsDialog showTipsDialog={showTipsDialog} closeTipsDialog={closeTipsDialog} tipsReamrk={tipsReamrk} tipsBtns={tipsBtns} />
      <FreeWhiteDialog showFreeWhiteDialog={showFreeWhiteDialog} closeFreeWhiteDialog={closeFreeWhiteDialog} title={title} remarkList={remarkList} />
    </div>
  )
}

export default ChristmasMint